import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntypedFormArray } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { Pipe, PipeTransform } from '@angular/core';

import { BaseFormControl } from '../base-control';
import { FormTagsField, FormTranslatableTagsField } from '../form-field';

import { createTagsFormGroup } from './functions';

@Component({
    selector: 'abs-tags',
    template: `
        <div class="form-group" *ngIf="parentFormGroup" [formGroup]="parentFormGroup">
            <label *ngIf="!field.hideLabel"
                >{{ field.label || (field.name | formLabel) }}
                <ion-icon class="info" *ngIf="field.description" name="information-circle" (click)="showDescription($event)"></ion-icon>
            </label>
            <ng-container *ngIf="debouncedFormVal$ | async as debouncedFormVal">
                <div
                    [formGroupName]="field.name"
                    class="tags"
                    *ngLet="this.field | absSelectOptions: debouncedFormVal : parentFormGroup | async as options"
                >
                    <span
                        *ngFor="let fc of formArray?.controls; let i = index"
                        (auxclick)="$event.which === 2 ? removeItem($event, i) : null"
                    >
                        {{ fc | tagLabel: options }}
                        <span class="removeTag" (click)="removeItem($event, i)">x</span>
                        <input type="hidden" [formControlName]="i" />
                    </span>

                    <input
                        type="text"
                        [value]="value"
                        (input)="updateValue($event)"
                        (keydown.Enter)="onEnter($event)"
                        absAutoComplete
                        [options]="options"
                        [mapOptionsFn]="field.mapItems"
                        [sortOptionsFn]="$any(field).sortOptionsFn ? $any(field).sortOptionsFn(formValue) : undefined"
                        (selectionUpdate)="selectionUpdate($event)"
                        (commitSelection)="commitSelection($event)"
                    />
                </div>
            </ng-container>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TagsComponent extends BaseFormControl {
    @Input()
    field: FormTagsField | FormTranslatableTagsField;

    value: string = '';

    options$ = new BehaviorSubject([]);

    get formArray() {
        return this.parentFormGroup.get(this.field.name) as UntypedFormArray;
    }

    getNewValue(value: string): any {
        // Done so TranslatableTagsComponent can override this method
        return value;
    }

    getFormGroup(): any {
        // Done so TranslatableTagsComponent can override this method
        return createTagsFormGroup();
    }

    onEnter(e) {
        const raw = e.srcElement.value.trim();
        const val = this.field?.mapValue ? this.field.mapValue(raw) : raw;

        if (val === null || val === undefined || this.formArray.value.includes(val)) {
            return;
        }
        this.addItem(val);
        e.target.focus();
    }

    addItem(val) {
        const newValue = [...this.formArray.value, this.getNewValue(val)];
        this.formArray.push(this.getFormGroup());
        this.formArray.patchValue(newValue);
        this.value = '';
    }

    removeItem(e: any, index: number) {
        e.preventDefault();
        e.stopPropagation();
        this.formArray.removeAt(index);
    }

    updateValue(e) {
        const val = e.target.value;
        this.value = val ?? '';
    }

    selectionUpdate(e) {
        const val = this.field?.mapValue ? this.field.mapValue(e) : e;
        this.value = val ?? '';
    }

    commitSelection(e) {
        const val = this.field?.mapValue ? this.field.mapValue(e) : e;

        this.addItem(val);
    }
}

@Pipe({
    name: 'tagLabel'
})
export class TagLabelPipe implements PipeTransform {
    transform(fc: any, options: any[]): any {
        // the id field is used by the complex tags component only

        const val = fc.value?.id ?? fc.value?.label ?? fc.value?.value ?? fc.value ?? fc;
        const res = options?.find((o) => o.value === val)?.label || val;

        return res;
    }
}

// @Pipe({
//     name: 'tagLabel',
//     standalone: true
// })
// export class FieldOptionsPipe implements PipeTransform {
//     transform(field: FieldWithOptions | FieldWithSrc, formValue: any): FieldOption[] {
//         // the id field is used by the complex tags component only
//         const val = fc.value?.id || fc.value?.label || fc.value?.value || fc.value || fc;
//         return options.find((o) => o.value === val)?.label || val;
//     }
// }
