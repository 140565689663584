import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { Observable, Subscription } from 'rxjs';

export interface NumberPickerOptions {
    title: string;
    okButtonText?: string;
    value: number;
    min?: number;
    max?: number;
    max$?: Observable<number>;
    decrement?: (count: number) => void;
    increment?: (count: number) => void;
    callback?: (count: number) => void;
}

@Injectable({ providedIn: 'root' })
export class NumberPicker {
    constructor(private alertController: AlertController) {}

    async show(options: NumberPickerOptions) {
        let sub: Subscription;
        if (options.max$) {
            sub = options.max$.subscribe((val) => (options.max = val));
        }

        const alert = await this.alertController.create({
            header: options.title,
            message: options.value.toString(),
            cssClass: 'alert-horizontal-buttons alert-number-picker',
            buttons: [
                {
                    text: '-',
                    role: '',
                    cssClass: 'alertButton',
                    handler: () => {
                        let count = parseInt(alert.message as string, 10);
                        if (count > (options.min ?? 0)) {
                            count--;
                            if (options.decrement) {
                                options.decrement(count);
                            }
                            alert.message = count.toString();
                        }
                        return false;
                    }
                },
                {
                    text: options.okButtonText || 'Okay',
                    cssClass: 'alertButton',
                    handler: () => {
                        let count = parseInt(alert.message as string, 10);
                        if (options.callback) {
                            options.callback(count);
                        }
                    }
                },
                {
                    text: '+',
                    role: '',
                    cssClass: 'alertButton',
                    handler: () => {
                        console.log(options.max);
                        let count = parseInt(alert.message as string, 10);
                        if (count < (options.max ?? Infinity)) {
                            count++;
                            if (options.increment) {
                                options.increment(count);
                            }
                            alert.message = count.toString();
                        }
                        return false;
                    }
                }
            ]
        });
        alert.onDidDismiss().then(() => {
            if (sub) {
                sub.unsubscribe();
            }
        });
        return await alert.present();
    }
}
