import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar, Style } from '@capacitor/status-bar';
import { BehaviorSubject } from 'rxjs';

import { ArmyBuilderConfig, DebugService, HttpClientWithInFlightCache, Logger, NativeStorageService } from './global';
import { SupportService } from './support';

@Component({
    selector: 'app-root',
    template: ``
})
export abstract class BaseAppComponent {
    supported = null;
    minClientVersion = null;
    currentClientVersion = null;
    otherClientActive$ = new BehaviorSubject<boolean>(false);
    protected logger: Logger;

    // These should come from package.json
    abstract appVersion: string;
    abstract clearDataBeforeVersion: string;

    constructor(
        protected platform: Platform,
        protected http: HttpClientWithInFlightCache,
        protected config: ArmyBuilderConfig,
        protected supportService: SupportService,
        protected storage: NativeStorageService,
        debugService: DebugService
    ) {
        this.initializeApp();

        this.logger = debugService.getInstance('AppComponent');
    }

    private async initializeApp() {
        const previousVersion = await this.storage.getItem('appVersion', null);
        const currentVersion = this.appVersion;

        console.log(`Caching app version ${currentVersion}`);
        await this.storage.setItem('appVersion', currentVersion);

        const timeToClearData = !this.versionCheck(previousVersion, this.clearDataBeforeVersion);
        if (!previousVersion || timeToClearData) {
            console.log('Clearing app data');
            this.storage.clear();
            window.location.reload();
            return;
        }

        return this.platform.ready().then((platform) => {
            if (platform !== 'dom') {
                StatusBar.setStyle({ style: Style.Default });
            }
            SplashScreen.hide();

            this.http.get(`${this.config.apiBaseUrl}/healthcheck`).subscribe((res: any) => {
                this.minClientVersion = res.minClientVersion;
                this.currentClientVersion = currentVersion;
                this.config.environment = res.environment;
                this.supported = this.versionCheck(currentVersion, res.minClientVersion);
                console.log(`Client version ${currentVersion}, API version: ${res.minClientVersion}, supported: ${this.supported}`);
            });

            this.init();
        });
    }

    abstract init();

    /**
     * Returns true if the client is greater than
     * or equal to the minClient
     */
    versionCheck(client, minClient) {
        const clientVersion = this.getVersionParts(client);
        const minClientVersion = this.getVersionParts(minClient);

        return this.isClientSupported(clientVersion, minClientVersion);
    }

    getVersionParts(version: string) {
        return version?.split('.').map((x) => parseInt(x));
    }

    isClientSupported(client, api) {
        console.log('isClientSupported', { client, api });
        for (let i = 0; i < client?.length; i++) {
            if (client[i] < api[i]) {
                return false;
            }
            if (client[i] > api[i]) {
                return true;
            }
        }
        return true;
    }

    reportProblem(e?) {
        if (e) {
            e.preventDefault();
        }
        this.supportService.showModal();
    }
}
