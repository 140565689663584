import { Component, inject, NgZone, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';

import { AlertService } from '../../../global/alert/alert';
import { ArmyBuilderConfig } from '../../../global/config';
import { InputAlert } from '../../../global/input-alert/input-alert';
import { ForceService } from '../../force.service';
import { ForceUtils } from '../../force.utils';
import { Force, Unit } from '../../models';
import { DataLibrary } from '../../../global';

@Component({
    selector: 'abs-unit-details-page',
    template: `
        <abs-page-layout
            *ngIf="forceService.unit$ | async as unit"
            (titleClicked)="renameUnit(unit)"
            [title]="unit.name"
            [subTitle]="unit.cost + ('GLOBAL.PTS' | translate)"
        >
            <div class="content">
                <abs-unit-details [unit]="unit"></abs-unit-details>
            </div>
        </abs-page-layout>
    `
})
export class UnitDetailsPage {
    public forceService = inject(ForceService);
    public dataLibrary = inject(DataLibrary);
    public forceUtils = inject(ForceUtils);

    public route = inject(ActivatedRoute);
    public router = inject(Router);
    public config = inject(ArmyBuilderConfig);
    public alertService = inject(AlertService);
    public inputAlert = inject(InputAlert);
    public modalController = inject(ModalController);

    renameUnit(_unit: Unit) {
        // this.inputAlert.show(this.config.renameForceDialog.title, this.config.renameForceDialog.message, unit.name, true).then(name => {
        //   if (!name) {
        //     return;
        //   }
        //   this.forceService.update({
        //     id: unit.id,
        //     name
        //   });
        // });
    }
}
