import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { Faction, UnitOption, UnitTemplate } from '../forces/models';

@Injectable({ providedIn: 'root' })
export abstract class DataLibrary {
    abstract factions$: Observable<Faction[]>;
    abstract unitTemplates$: Observable<UnitTemplate[]>;
    abstract options$: Observable<UnitOption[]>;

    ready$ = new BehaviorSubject<boolean>(false);
    abstract init(): void;
}
