import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { ForceSyncService } from './sync.force';

@Component({
    selector: 'wl-sync-conflict',
    template: `
        <abs-modal-layout>
            <h2 *ngIf="item?.name">{{ item?.name }}</h2>
            {{ 'GLOBAL.ERRORS.SYNC_CONFLICT.MESSAGE' | translate }}
            <div>
                <ion-button (click)="keepLocal()">{{ 'GLOBAL.ERRORS.SYNC_CONFLICT.KEEP_LOCAL' | translate: conflict }}</ion-button>
                <ion-button (click)="keepRemote()">{{ 'GLOBAL.ERRORS.SYNC_CONFLICT.KEEP_REMOTE' | translate: conflict }}</ion-button>
            </div>
        </abs-modal-layout>
    `
})
export class SyncConflictComponent implements OnInit {
    @Input()
    localTimestamp: number;

    @Input()
    remoteTimestamp: number;

    @Input()
    item: { id: string; name: string };

    @Input({ required: true })
    uploadItem: (item: { id: string }) => void;

    @Input({ required: true })
    downloadItem: (itemId: string) => void;

    conflict = {
        remoteUpdate: '',
        localUpdate: ''
    };

    constructor(
        private syncService: ForceSyncService,
        private modalController: ModalController
    ) {}

    ngOnInit() {
        this.conflict.localUpdate = this.formatDate(this.localTimestamp);
        this.conflict.remoteUpdate = this.formatDate(this.remoteTimestamp);
    }

    private formatDate(timestamp: number): string {
        return new Date(timestamp).toLocaleString();
    }

    keepLocal() {
        this.uploadItem(this.item);
        this.modalController.dismiss();
    }

    keepRemote() {
        this.downloadItem(this.item.id);
        this.modalController.dismiss();
    }
}
