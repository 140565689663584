import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

import { ModalLayoutModule } from '../modal-layout';

import { SyncConflictComponent } from './sync-conflict.component';

@NgModule({
    imports: [CommonModule, IonicModule, ModalLayoutModule, TranslateModule],
    exports: [SyncConflictComponent],
    declarations: [SyncConflictComponent],
    providers: []
})
export class DataSyncModule {}
