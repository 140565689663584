import { Component } from '@angular/core';
import { UntypedFormArray } from '@angular/forms';
import { ItemReorderEventDetail } from '@ionic/angular';
import { createForm, patchForm } from '../../config';
import { BaseFormControl } from '../base-control';
import { FormArrayField } from '../form-field';

@Component({ template: '' })
export class BaseFormArrayComponent extends BaseFormControl {
    field: FormArrayField;
    header: string;

    get fields() {
        return this.parentFormGroup.get(this.field.name) as UntypedFormArray;
    }

    selectedItemId: number;
    fixNumericalIds = true;

    ngAfterViewInit() {
        if (this.field.fixNumericalIds) {
            let nextId = this.fields.value.map((x) => x.id).sort((a, b) => b - a)[0] + 1 || 0;
            setTimeout(() => {
                let newValue = this.fields.value.map((x, i, all) => {
                    if (x.id === undefined || x.id === null || isNaN(x.id)) {
                        x.id = nextId;
                        nextId++;
                    }
                    return x;
                });
                this.fields.setValue(newValue);
            }, 1000);
        }
    }

    close() {
        console.log('Close');
        this.selectedItemId = null;
    }

    addItem(item: any = {}) {
        let newId = this.fields.value.map((x) => x.id).sort((a, b) => b - a)[0] + 1 || 0;

        let newItem: any = { ...item, id: newId };
        let newValue = [...this.fields.value, newItem];

        let newForm = createForm(this.field.fields);
        patchForm(newForm, this.field.fields, newItem);
        this.fields.push(newForm);

        // this.fields.patchValue(newValue);
        this.selectedItemId = newId;
    }

    getItemHeaderColor(item) {
        return this.selectedItemId === item.value.id ? 'primary' : '';
    }

    showItem(e: KeyboardEvent | MouseEvent, item) {
        console.log({ e });
        if (e.altKey) {
            this.clickThrough(item);
        } else {
            if (this.selectedItemId === item.id) {
                this.selectedItemId = null;
            } else {
                this.selectedItemId = item.id;
            }
        }
    }

    deleteItem(itemIndex: number) {
        this.fields.removeAt(itemIndex);
    }

    doReorder(ev: CustomEvent<ItemReorderEventDetail>) {
        let { from, to, complete } = ev.detail;
        let item = this.fields.at(from);
        this.fields.removeAt(from);
        this.fields.insert(to, item);
        let items: any[] = this.fields.value;
        complete();
        this.fields.setValue(items);
    }

    clickThrough(item) {
        console.log(item);
        if (this.field.clickThrough) {
            this.field.clickThrough(item);
        }
    }
}
