import { createReducer, on, Action } from '@ngrx/store';
import { Force } from './models';
import { sharedForcesLoaded, removeSharedForce } from './shared-forces.actions';

export interface SharedForcesState {
    [gameId: string]: Force[];
}

export const initialSharedForcesState: SharedForcesState = {};

export function sharedForcesReducer(state: SharedForcesState | undefined, action: Action) {
    return createReducer(
        initialSharedForcesState,
        on(sharedForcesLoaded, (state: any, action) => ({
            ...state,
            [action.gameId]: [...action.forces]
        })),
        on(removeSharedForce, (state: any, action) => {
            return {
                ...state,
                [action.gameId]: [...(state[action.gameId] || []).filter((f) => f.id !== action.forceId)]
            };
        })
    )(state, action);
}
