import { forkJoin, Observable, of } from 'rxjs';
import { map, defaultIfEmpty, catchError, shareReplay, switchMap, retry } from 'rxjs/operators';

import { Translation } from './i18n';
import { getValueFromTranslation } from './language';
import { PaginatedItems } from './models';

export function mapToPaginatedItems<T>() {
    return function (source: Observable<PaginatedItems>): Observable<T[]> {
        return source.pipe(
            map((x) => x.items),
            map((data) => data as T[]),
            defaultIfEmpty([]),
            catchError(() => of([])),
            shareReplay(1)
        );
    };
}

export interface NamedEntity {
    name: Translation | string;
    key?: string;
}

export function sortEntitiesByNameTranslation<T extends NamedEntity>() {
    return function (source: Observable<T[]>): Observable<T[]> {
        return source.pipe(
            switchMap((items: T[]) => {
                const itemsWithSortValue$ = forkJoin(
                    items.map((item) =>
                        getValueFromTranslation(item.name).pipe(
                            map((itemName) => ({
                                sortBy: itemName,
                                value: item
                            }))
                        )
                    )
                );

                return itemsWithSortValue$.pipe(
                    map((items) => items.sort((a, b) => a.sortBy.localeCompare(b.sortBy)).map((item) => item.value))
                );
            })
        );
    };
}
