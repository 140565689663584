import { TranslateService } from '@ngx-translate/core';
import { AppInjector } from '../app.injector';
import { ParseIconsConfig } from './parse-icons.config';

export const parseIcons = (content: string, gameId?: string) => {
    if (!content) {
        return '';
    }
    let translateService = AppInjector.get(TranslateService);
    let parseIconsConfig = AppInjector.get(ParseIconsConfig);

    const folder = gameId ? `assets/${gameId}/images/icons` : parseIconsConfig.iconsFolder;
    console.log({ parseIconsConfig });
    content = content.replace(/\{\{(.*?)\}\}/g, (_match, grp1) => translateService.instant(grp1));
    content = content
        .replace(
            /\[\[(.*?)\|(.*?)\|(tiny|small|medium|large|vLarge|auto)\]\]/g,
            `<img src="${folder}/$1.png" alt="$2" title="$2" class="$3Icon" />`
        )
        .replace(/\[\[(.*?)\|(tiny|small|medium|large|vLarge|auto)\]\]/g, `<img src="${folder}/$1.png" class="$2Icon" />`)
        .replace(/\[\[(.*?)\|(.*?)\]\]/g, `<img src="${folder}/$1.png" alt="$2" title="$2" class="mediumIcon" />`)
        .replace(/\[\[(.*?)\]\]/g, `<img src="${folder}/$1.png" class="mediumIcon" />`);

    return content;
};
