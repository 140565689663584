import { inject, Injectable, Optional, SkipSelf, OnDestroy } from '@angular/core';
import { Platform } from '@ionic/angular';
import {
    ArmyBuilderConfig,
    DebugService,
    HttpClientWithInFlightCache,
    Logger,
    Product,
    SettingsService,
    snapshot
} from 'army-builder-shared';
import { distinctUntilChanged, filter, firstValueFrom, map, Observable, Subscription, tap } from 'rxjs';
import { Store } from '@ngrx/store';
import { Capacitor } from '@capacitor/core';
import { Purchases, PurchasesPackage } from '@awesome-cordova-plugins/purchases/ngx';

import { selectProducts, selectPurchases, selectStatus, selectRestoringPurchases, AddProduct, SetProductsStatus } from './state';

@Injectable()
export abstract class PurchasingService implements OnDestroy {
    platformKey: 'apple' | 'google' | 'stripe';

    config = inject(ArmyBuilderConfig);
    abstract configurePlatform(userId: string): void;
    abstract loadProductsForPlatform();

    public products$: Observable<Product[]> = this.store.select(selectProducts);
    public products: Product[] = [];
    public purchases$: Observable<string[]> = this.store.select(selectPurchases);
    public status$: Observable<string[]> = this.store.select(selectStatus);
    public restoringPurchases$: Observable<boolean> = this.store.select(selectRestoringPurchases);

    private storageSub: Subscription;
    private purchasesSub: Subscription;
    private productsSub: Subscription;
    private loginSub: Subscription;
    protected debug: Logger;

    constructor(
        public platform: Platform,
        protected store: Store,
        debugService: DebugService,
        // protected config: ArmyBuilderConfig,
        protected purchases: Purchases,
        protected http: HttpClientWithInFlightCache,
        private settingsService: SettingsService,
        @Optional() @SkipSelf() parent?: PurchasingService
    ) {
        if (parent) {
            throw Error(`[PurchasingService]: trying to create multiple instances, but this service should be a singleton.`);
        }

        this.debug = debugService.getInstance('[Purchasing Service]');
        this.platform.ready().then(async () => {
            this.loginSub = this.settingsService.login$
                .pipe(
                    filter((l) => l.user),
                    map((l) => l.user.dbUserId),
                    distinctUntilChanged()
                )
                .subscribe(async (dbUserId) => {
                    this.configurePlatform(dbUserId);
                    this.loadProducts();
                });
        });
    }

    ngOnDestroy(): void {
        this.storageSub.unsubscribe();
        this.purchasesSub.unsubscribe();
        this.productsSub.unsubscribe();
        if (this.loginSub) {
            this.loginSub.unsubscribe();
        }
    }

    async loadProducts() {
        const productsURL = this.config.apiBaseUrl + '/library/global/products?getAll=1';
        const apiProducts = await firstValueFrom(
            this.http.get(productsURL, { requiresLogin: true }).pipe(
                map((res) => res.items as Product[]),
                tap((p) => console.log(`configurePurchasing: ${p.length} products found`))
            )
        );

        const rcProducts = this.loadProductsForPlatform();

        apiProducts.forEach((product) => {
            // Add the products to the store, even though we don't have the
            // RC package details yet.  The current product data is needed
            // to list the products on the purchase page anyway, and package
            // info isn't needed yet.

            const pkg = rcProducts?.find((p: PurchasesPackage) => p.identifier === product.key);
            this.store.dispatch(
                AddProduct({
                    product: {
                        ...product,
                        displayPrice: pkg?.product?.priceString ?? product.displayPrice,
                        package: pkg
                    }
                })
            );
        });
    }

    private addPurchase = (productId: string) => {
        // this.debug.log('addPurchase: ' + productId);
        // this.refreshUserPurchases();
        // this.store.dispatch(SetProductsStatus({ productId, status: 'loaded' }));
    };

    async purchase(productId) {
        if (Capacitor.getPlatform() === 'web') {
            return;
        }
        this.store.dispatch(SetProductsStatus({ productId, status: 'loading' }));
        snapshot(this.products$, (products) => {
            const pkg = products.find((p) => p.key === productId).package;
            this.purchases
                .purchasePackage(pkg)
                .then(({ productIdentifier, customerInfo }) => {
                    this.debug.log(JSON.stringify({ productId, pkg, productIdentifier, customerInfo }));
                    if (typeof customerInfo.entitlements.active[productId] !== 'undefined') {
                        this.addPurchase(productId);
                        this.debug.log(`${productId} added`);
                    }
                })
                .catch(({ error, userCancelled }) => {
                    // Error making purchase
                    console.log(error, userCancelled);
                    this.debug.log('Error during purchase: ' + JSON.stringify({ error, userCancelled }));
                });
        });
    }

    refreshUserPurchases() {
        // this.log('refreshUserPurchases');
        // this.purchases.getCustomerInfo().then((customerInfo) => {
        //     this.log('refreshUserPurchases', { customerInfo });
        //     const productIds = this.products.map((p) => p.key);
        //     const entitlements = Object.values(customerInfo.entitlements.active).map((e) => e.identifier);
        //     productIds.forEach((productId) => {
        //         if (this.userPurchases.includes(productId) && !entitlements.includes(productId)) {
        //             this.log('Removing purchase from app state: ', productId);
        //             this.store.dispatch(RemovePurchase({ productId }));
        //         } else if (!this.userPurchases.includes(productId) && entitlements.includes(productId)) {
        //             this.log('Adding purchase to app state: ', productId);
        //             this.store.dispatch(AddPurchase({ productId }));
        //         }
        //     });
        //     this.userPurchases = entitlements;
        // });
    }

    isOwned(productId: string): boolean {
        // const purchaseKey = productId.toLowerCase();
        // if (purchaseKey === 'core') {
        //     return true;
        // }

        // // if (!this.platform.is('cordova')) {
        // //     return true;
        // // }

        // return this.userPurchases.includes('all_access') || this.userPurchases.includes(purchaseKey);
        return false;
    }

    // private addOwnedPurchases() {
    //     this.store.dispatch(PurchasesLoading());
    //     this.purchases.syncPurchases();

    //     this.purchases.restorePurchases();
    //     this.refreshUserPurchases();
    //     this.store.dispatch(PurchasesLoaded());
    // }

    async restorePurchases() {
        // console.log('restorePurchases', { configured: this.configured });
        // if (!this.configured) {
        //     await this.configurePurchasing();
        // }
        // this.addOwnedPurchases();
    }

    isItemAvailable(item: any): boolean {
        // if (!item?.packs) {
        //     return false;
        // }
        // for (const pack of item.packs) {
        //     if (this.isOwned(pack)) {
        //         return true;
        //     }
        // }
        return false;
    }
}
