import { Injectable } from '@angular/core';

import { ForceSyncService } from './sync.force';
import { SettingsSyncService } from './sync.settings';
import { BaseSyncService } from './sync.base';

@Injectable({ providedIn: 'root' })
export class DataSyncService {
    constructor(
        private forceSyncService: ForceSyncService,
        private settingsSyncService: SettingsSyncService
    ) {}

    customSyncServices: BaseSyncService[] = [];

    registerCustomSyncService(service: BaseSyncService) {
        this.customSyncServices.push(service);
    }

    /**
     * Executes synchronization operations for the forceSyncService, settingsSyncService, and customSyncServices.
     *
     * This function triggers the synchronization of the forceSyncService, settingsSyncService, and all customSyncServices.
     * It calls the `sync()` method of each customSyncService in the `customSyncServices` array.
     *
     * @return {void} This function does not return anything.
     */
    sync() {
        this.forceSyncService.sync();
        this.settingsSyncService.sync();

        this.customSyncServices.forEach((s) => s.sync());
    }
}
