import { Component, OnDestroy, Input, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { BattleService } from '../../service/battle.service';
import { ArmyBuilderConfig } from '../../../global/config';
import { Unit } from '../../../forces/models';
import { BattleUnit } from '../../models';

@Component({
    selector: 'abs-battle',
    template: `
        <abs-page-layout
            *ngLet="{ round: round$ | async, units: units$ | async } as props"
            [title]="'BATTLE.HEADERS.BATTLE_ROUND' | translate: props"
        >
            <div class="header">
                <ng-content select=".beforeButtons"></ng-content>
                <div class="buttons">
                    <ion-button class="addForce" [routerLink]="['/battle', 'addForce']">+</ion-button>
                    <ion-button class="reset" (click)="reset()">Reset</ion-button>
                    <ion-button class="nextRound" (click)="nextRound()">Next Round</ion-button>
                </div>
                <ng-content select=".afterButtons"></ng-content>
            </div>
            <abs-list
                [items]="props.units"
                [groupBy]="'forceId'"
                [groupNameField]="'forceName'"
                (itemSelect)="itemSelected($event.item)"
                [showDelete]="true"
                (deleteItem)="deleteUnit($event.item)"
                [template]="itemTemplate"
            ></abs-list>
        </abs-page-layout>
    `
})
export class BattlePage implements OnDestroy {
    units$ = this.battleService.units$;
    units: BattleUnit[];
    unitsSub: Subscription;
    round$ = this.battleService.round$;

    @Input() itemTemplate: TemplateRef<any>;

    constructor(
        public battleService: BattleService,
        public config: ArmyBuilderConfig,
        public router: Router,
        public route: ActivatedRoute
    ) {
        this.unitsSub = this.units$.subscribe((units) => {
            this.units = units;
        });
    }

    itemSelected(unit: Unit) {
        this.router.navigate([unit.id], { relativeTo: this.route });
    }

    deleteUnit(unit: Unit) {
        this.battleService.delete(unit.id);
    }

    reset() {
        this.units?.forEach((unit) => {
            this.battleService.delete(unit.id);
        });
        this.battleService.resetBattle();
    }

    nextRound() {
        this.battleService.nextRound();
    }

    ngOnDestroy() {
        this.unitsSub.unsubscribe();
    }
}
