import { createAction, props } from '@ngrx/store';

import { BattleUnit } from '../models';

import { BattleState, GlobalBattleState } from './reducer';

export const nextRound = createAction('[Battle] nextRound', props<{ gameId: string }>());
export const setRound = createAction('[Battle] setRound', props<{ gameId: string; round: number }>());
export const resetRound = createAction('[Battle] resetRound', props<{ gameId: string }>());
export const setBattleData = createAction('[Battle] setBattleData', props<{ newState: GlobalBattleState }>());
export const resetBattle = createAction('[Battle] resetBattle', props<{ gameId: string; newState: BattleState }>());
export const addUnitToBattle = createAction('[Battle] addUnitToBattle', props<{ gameId: string; unit: BattleUnit }>());
export const saveBattleUnit = createAction('[Battle] saveBattleUnit', props<{ gameId: string; unit: BattleUnit }>());
export const setUnitStatus = createAction(
    '[Battle] setUnitStatus',
    props<{ gameId: string; battleUnitId: string; statusKey: string; statusValue: any }>()
);
export const removeUnitFromBattle = createAction('[Battle] removeUnitFromBattle', props<{ gameId: string; battleUnitId: string }>());
export const renameUnit = createAction('[Battle] renameUnit', props<{ gameId: string; battleUnitId: string; newName: string }>());
