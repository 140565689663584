export interface PDFConfig {
    includeUnitData?: boolean;
    specialRules?: 'FULL' | 'SUMMARY' | 'NONE';
    includeWeaponData?: boolean;
    selectedOptions?: boolean;
}

export const DEFAULT_PDF_CONFIG: PDFConfig = {
    includeUnitData: true,
    specialRules: 'SUMMARY',
    includeWeaponData: true,
    selectedOptions: true
};

export interface PDFSettingsFields {
    specialRules?: boolean;
    weaponData?: boolean;
    selectedOptions?: boolean;
    unitData?: boolean;
}

export const DEFAULT_PDF_FIELDS: PDFSettingsFields = {
    specialRules: true,
    weaponData: true,
    selectedOptions: true,
    unitData: true
};
