import { TextboxComponent } from '../textbox/textbox.component';
import { ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild } from '@angular/core';
import * as ace from 'ace-builds';
import { FormCodeField } from '../form-field';

@Component({
    selector: 'abs-code',
    template: `
        <div *ngIf="parentFormGroup" [formGroup]="parentFormGroup">
            <div class="form-group" [formGroupName]="field.name" *ngIf="field.type === 'html'">
                <label *ngIf="!field.hideLabel">{{ field.label || (field.name | formLabel) }} </label>
                <div class="app-ace-editor" #editor></div>
                <textarea #value [formControlName]="'en'"></textarea>
            </div>

            <div class="form-group" *ngIf="field.type === 'json'">
                <label *ngIf="!field.hideLabel">{{ field.label || (field.name | formLabel) }} </label>
                <div class="app-ace-editor" #editor></div>
                <textarea #value [formControlName]="field.name"></textarea>
            </div>
        </div>
    `,
    styles: [
        `
            .app-ace-editor {
                border: 2px solid #f8f9fa;
                box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
                min-height: 100px;
                z-index: 1;
            }

            .app-ace-editor * {
                font-family: monospace !important;
                font-size: 16px !important;
                direction: ltr !important;
                text-align: left !important;
            }

            textarea {
                display: none;
            }
        `
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CodeFormComponent extends TextboxComponent {
    @Input()
    field: FormCodeField;

    @ViewChild('editor') private editor: ElementRef<HTMLElement>;

    aceEditor: ace.Ace.Editor;

    ngAfterViewInit(): void {
        setTimeout(() => {
            ace.config.set('fontSize', '14px');
            ace.config.set('basePath', 'https://unpkg.com/ace-builds@1.4.12/src-noconflict');

            this.aceEditor = ace.edit(this.editor.nativeElement);
            this.aceEditor.setTheme('ace/theme/twilight');
            this.aceEditor.setOptions({
                maxLines: Infinity,
                indentedSoftWrap: false
            });
            this.aceEditor.session.setUseWrapMode(true); // For some reason this doesn't work in setOptions
            this.aceEditor.session.setMode('ace/mode/' + this.field.type);

            let val = this.formControl.value;
            const isTranslatable = val?.hasOwnProperty('en');
            console.log({ isTranslatable });

            if (isTranslatable) {
                val = val.en; // TODO: Support multiple languages
            }
            if (typeof val !== 'string') {
                val = JSON.stringify(val, null, 2);
            }
            this.aceEditor.session.setValue(val);

            this.aceEditor.on('change', () => {
                let control = this.formControl;
                if (isTranslatable) {
                    control = (this.formControl as any).controls.en;
                }
                let val: string | object = this.aceEditor.getValue();
                if (this.field.raw) {
                    try {
                        val = JSON.parse(val);
                        control.patchValue(val);
                    } catch {}
                } else {
                    control.patchValue(val);
                }
            });
        }, 500); // using a timeout is a bit hacky
    }
}
