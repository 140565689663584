import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { NgLetModule } from 'ng-let';
import { CustomBehavioursModule } from '../../../../global';
import { EntityListFiltersComponent } from './entity-list-filters.component';

const ENTITY_FILTER_COMPONENTS = [EntityListFiltersComponent];

@NgModule({
    imports: [CommonModule, IonicModule, NgLetModule, RouterModule, CustomBehavioursModule],
    exports: [...ENTITY_FILTER_COMPONENTS],
    declarations: [...ENTITY_FILTER_COMPONENTS],
    providers: []
})
export class EntityFiltersModule {}
