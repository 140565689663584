import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ThemeService {
    theme$ = new BehaviorSubject('');

    selectTheme(name: string) {
        this.theme$.next(name);
    }
}
