<div class="form-group" *ngIf="parentFormGroup" [formGroup]="parentFormGroup">
    <label *ngIf="!field.hideLabel">{{ field.label || (field.name | formLabel) }}
        <ion-icon class="info" *ngIf="field.description" name="information-circle"
            (click)="showDescription($event)"></ion-icon></label>
    <div [formGroupName]="field.name" class="tags">
        <span *ngFor="let control of fields.controls; let i = index" [formGroup]="$any(control)"
            (click)="showItem($event, control.value)" (auxclick)="$event.which === 2 ? removeItem($event, i) : null">
            {{ field | arrayFieldItemName : control.value : i : formValue }}

            <div class="arrayFieldItem" [class.hidden]="control.value.id !== selectedItemId" [formGroup]="$any(control)"
                (click)="close()">
                <ion-card (click)="$event.stopPropagation()" *ngIf="!field.inline">
                    <ion-item lines="full">
                        <ion-icon name="create" slot="start"></ion-icon>
                        <ion-title>Edit {{ field.itemName }}</ion-title>
                        <ion-icon name="close" slot="end" (click)="close()"></ion-icon>
                    </ion-item>
                    <ion-card-content>
                        <abs-universal-field *ngFor="let itemField of field.fields" [field]="itemField"
                            [formValue]="formValue"></abs-universal-field>
                    </ion-card-content>
                    <ion-footer>
                        <ion-item lines="none">
                            <ion-button color="danger" slot="start" (click)="deleteItem(i)">Delete {{ field.itemName
                                }}</ion-button>
                            <ion-button color="warning" slot="start" (click)="addItem(control.value)">Duplicate {{
                                field.itemName }}</ion-button>
                            <ion-button fill="outline" slot="end" (click)="close()">Close</ion-button>
                        </ion-item>
                    </ion-footer>
                </ion-card>
                <div (click)="$event.stopPropagation()" *ngIf="field.inline">
                    <abs-universal-field *ngFor="let itemField of field.fields" [field]="itemField"
                        [formValue]="formValue"></abs-universal-field>
                    <ion-item lines="none">
                        <ion-button color="danger" slot="start" (click)="deleteItem(i)">Delete {{ field.itemName
                            }}</ion-button>
                        <ion-button color="warning" slot="start" (click)="addItem(control.value)">Duplicate {{
                            field.itemName }}</ion-button>
                        <ion-button fill="outline" slot="end" (click)="close()">Close</ion-button>
                    </ion-item>
                </div>
            </div>

            <span class="removeTag" (click)="deleteItem(i)">x</span>
        </span>

        <input type="text" [value]="value" (input)="updateValue($event)" (keydown.Enter)="onEnter($event)"
            absAutoComplete [options]="field.options" [mapOptionsFn]="field.mapItems"
            [sortOptionsFn]="field.sortOptionsFn ? field.sortOptionsFn(formValue) : undefined"
            (selectionUpdate)="selectionUpdate($event)" (commitSelection)="commitSelection($event)" />
    </div>
</div>