import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { mapToPaginatedItems, PaginatedItems, RestDataService, Translation } from 'army-builder-shared';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { environment } from 'warlord/src/environments/environment';

@Injectable({ providedIn: 'root' })
export class ContentPageService {
    route$: Observable<any> = this.store.select((s: any) => s.router).pipe(distinctUntilChanged());

    pageId$: Observable<any> = this.route$.pipe(
        map((r) => r.state.params.pageId),
        distinctUntilChanged()
    );

    page$ = this.pageId$.pipe(
        switchMap((pageId) => {
            return this.restDataService
                .get(`${environment.apiBaseUrl}/library/global/pages?pageSize=1&skip=0&query=url:${pageId}`, {
                    cacheKey: `page_${pageId}`,
                    requiresLogin: true
                })
                .pipe(
                    mapToPaginatedItems<ContentPage>(),
                    map((p: ContentPage[]) => p[0])
                );
        })
    );
    constructor(private restDataService: RestDataService, private store: Store) {}
}

export interface ContentPage {
    _id: string;
    name: Translation;
    url: string;
    content: Translation;
}
