import { Component, Input, NgZone, OnInit } from '@angular/core';
import { ControlContainer, UntypedFormGroup } from '@angular/forms';
import { ModalController, PopoverController } from '@ionic/angular';
import { Observable, throttleTime } from 'rxjs';

import { ArmyBuilderConfig } from '../../global';

import { FormField } from './form-field';
import { TooltipComponent } from './tooltip/tooltip.component';

@Component({
    template: ``
})
export abstract class BaseFormControl implements OnInit {
    public parentFormGroup: UntypedFormGroup;

    @Input()
    public field: FormField;

    @Input()
    formValue: any;

    formValue$: Observable<any>;
    debouncedFormVal$: Observable<any>;

    constructor(
        public config: ArmyBuilderConfig,
        public controlContainer: ControlContainer,
        public zone: NgZone,
        public modalController: ModalController,
        private popoverController: PopoverController
    ) {}

    ngOnInit() {
        this.parentFormGroup = this.controlContainer.control as any;
        this.formValue$ = this.parentFormGroup.root.valueChanges;
        this.parentFormGroup.updateValueAndValidity({ onlySelf: false, emitEvent: true });
        this.debouncedFormVal$ = this.formValue$.pipe(throttleTime(1000, undefined, { leading: true, trailing: true }));
    }

    async showDescription(ev) {
        const popover = await this.popoverController.create({
            component: TooltipComponent,
            event: ev,
            componentProps: {
                description: this.field.description
            }
        });
        popover.present();
    }
}
