import { createReducer, on, Action } from '@ngrx/store';
import { SupportIssue } from './model';
import { loadIssuesComplete, markIssueAsRead, updateIssue } from './support.actions';

export interface SupportState {
    issues: SupportIssue[];
}

export const initialSupportState: SupportState = {
    issues: null
};

export function supportReducer(state: SupportState | undefined, action: Action) {
    return createReducer(
        initialSupportState,
        on(loadIssuesComplete, (state: any, action) => ({
            ...state,
            issues: action.issues
        })),
        on(updateIssue, (state: any, action) => ({
            ...state,
            issues: state.issues.map((i) => {
                if (i._id === action._id) {
                    return action;
                }
                return i;
            })
        })),
        on(markIssueAsRead, (state: any, action) => ({
            ...state,
            issues: state.issues.map((i) => {
                if (i._id === action.issueId) {
                    return {
                        ...i,
                        unread: false
                    };
                }
                return i;
            })
        }))
    )(state, action);
}
