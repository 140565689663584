import { Component, Input, EventEmitter, Output, HostListener, Injector, ComponentFactoryResolver } from '@angular/core';
import { AngularDelegate, ModalController } from '@ionic/angular';
import { EnvironmentInjector } from '@angular/core';

@Component({
    selector: 'abs-modal-layout',
    template: `
        <div class="modalOuterWrapper" [class.disableSizeConstraint]="disableSizeConstraint">
            <ng-template #content><ng-content></ng-content></ng-template>
            <ion-card class="modal {{ cssClass }}" (click)="preventClose($event)" *ngIf="!hideWrapper">
                <ion-card-header *ngIf="title || subtitle">
                    <div class="title">
                        <ion-card-title *ngIf="title">{{ title }}</ion-card-title>
                        <ion-card-subtitle *ngIf="subtitle">{{ subtitle }}</ion-card-subtitle>
                    </div>
                    <img class="icon" *ngIf="icon" [src]="icon" />
                </ion-card-header>
                <ion-card-content><div [ngTemplateOutlet]="content"></div></ion-card-content>
            </ion-card>
            <div class="wrapperless" *ngIf="hideWrapper">
                <div [ngTemplateOutlet]="content"></div>
            </div>
            <div class="buttons" *ngIf="showClose || showCancel">
                <ion-button *ngIf="showCancel" class="modalClose cancel" [color]="'danger'" (click)="cancelHandler(true, $event)">{{
                    cancelBtnText || (cancelBtnTextKey | translate)
                }}</ion-button>
                <ion-button class="modalClose submit" *ngIf="showClose" (click)="closeHandler(true, $event)" [disabled]="disableCloseBtn">{{
                    closeBtnText || (closeBtnTextKey | translate)
                }}</ion-button>
            </div>
        </div>
    `,
    styles: [
        `
            :host {
                overflow: hidden;
                display: block;
                height: 100vh;
            }

            .modalOuterWrapper {
                --ion-background-color: none;
                --ion-card-background: #fff;
                padding: calc(20px + var(--ion-safe-area-top, 0)) 20px calc(20px + var(--ion-safe-area-bottom, 0));
                display: flex;
                flex-direction: column;
                justify-content: center;
                height: 100%;
            }

            .modalOuterWrapper.disableSizeConstraint {
                padding: 0;
            }

            .wrapperless {
                overflow: auto;
                margin: 0 auto;
                width: 100%;
                max-width: 600px;
                max-height: 100%;
                overflow: visible;

                margin-top: var(--ion-safe-area-top, 0);
                margin-bottom: var(--ion-safe-area-bottom, 0);
            }

            .disableSizeConstraint .wrapperless {
                max-width: 100%;
                max-height: 100%;
            }

            ion-button {
                margin: 0 10px;
            }

            ion-button:first-child {
                margin-left: 20px;
            }

            ion-button:last-child {
                margin-right: 20px;
            }

            .card {
                margin: 0 0 10px;
            }

            ion-card {
                overflow: auto;
                width: 100%;
                max-width: 600px;
                margin: 20px auto;
                margin-top: calc(20px + var(--ion-safe-area-top, 0));
                margin-bottom: calc(20px + var(--ion-safe-area-bottom, 0));
            }

            .buttons {
                display: flex;
                flex-direction: row;
                max-width: 600px;
                width: 100%;
                padding: 0;
                margin: 20px auto;
            }

            ion-card + .buttons {
                margin-top: 0;
            }

            .buttons ion-button:first-child {
                margin-left: 0;
            }

            .buttons ion-button:last-child {
                margin-right: 0;
            }

            .buttons.vertical ion-button:first-child {
                margin-left: 20px;
                margin-top: 0;
            }

            .buttons.vertical ion-button:last-child {
                margin-top: 20px;
                margin-bottom: 0;
            }

            ion-card-header {
                display: flex;
                align-items: flex-start;
            }

            .title {
                flex: 1 1 auto;
            }

            .icon {
                flex: 0 0 auto;
            }
        `
    ]
})
export class ModalLayoutComponent extends ModalController {
    @HostListener('click') onClick(e) {
        if (this.backdropDismiss) {
            this.closeHandler(false, e);
        }
    }

    @Input()
    title: string;

    @Input()
    subtitle: string;

    @Input()
    icon: string;

    @Input()
    closeBtnTextKey: string = 'GLOBAL.CLOSE';

    @Input()
    closeBtnText: string = null;

    @Input()
    cancelBtnTextKey: string = 'GLOBAL.CANCEL';

    @Input()
    cancelBtnText: string = null;

    @Input()
    disableCloseBtn: boolean = false;

    @Input()
    showClose: boolean = true;

    @Input()
    showCancel: boolean = false;

    @Input()
    hideWrapper: boolean = false;

    @Input()
    disableSizeConstraint: boolean = false;

    @Input()
    backdropDismiss: boolean = true;

    @Input()
    cssClass: string = '';

    @Output()
    close = new EventEmitter<UIEvent>();

    @Output()
    cancel = new EventEmitter<UIEvent>();

    @Input()
    blockingCloseHandler: (event?: UIEvent) => Promise<any>;

    closeHandler(emitClose = true, e?: UIEvent) {
        if (e) {
            e.stopPropagation();
        }
        if (emitClose) {
            this.close.emit(e);
        }
        if (this.blockingCloseHandler) {
            this.blockingCloseHandler(e).then(() => {
                this.dismiss({ dismiss: true });
            });
        } else {
            this.dismiss({ dismiss: true });
        }
    }

    cancelHandler(emitClose = true, e?: UIEvent) {
        if (e) {
            e.stopPropagation();
        }
        if (emitClose) {
            this.cancel.emit(e);
        }
        this.dismiss({ dismiss: true });
    }

    preventClose(e) {
        e.stopPropagation();
    }
}
