import { AfterViewInit, Component, ComponentFactoryResolver, Input, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { createQueryFilter } from './createQueryFilter';
import { CustomBehaviourHostDirective, snapshot } from '../../../../global';
import { EntityFormConfig } from '../../service/entity-form-config';
import { BaseEntityFilterComponent } from './base-entity-filter.component';

@Component({
    selector: 'abs-entity-list-filters',
    template: `
        <div class="content" *ngIf="!showAdvanced">
            <ng-template customBehaviourHost></ng-template>

            <button class="advFilterBtn" (click)="showAdvanced = true">Show Advanced Filter</button>
        </div>
        <ng-container *ngIf="showAdvanced">
            <div class="advanced">
                <div class="content" *ngIf="showAdvanced">
                    <button class="advFilterBtn" (click)="showAdvanced = false">Hide Advanced Filter</button>
                </div>
                <ion-searchbar [value]="query$ | async" (ionInput)="filterEntities($any($event).detail.value)"></ion-searchbar>
            </div>
        </ng-container>
    `,
    styles: [
        `
            .advFilterBtn {
                background: none;
                padding: 0;
                color: #449;
                float: right;
            }
            .advFilterBtn:hover {
                text-decoration: underline;
            }
        `
    ]
})
export class EntityListFiltersComponent implements AfterViewInit {
    showAdvanced = false;
    query$ = this.route.queryParams.pipe(map((qp) => decodeURIComponent(qp?.query)));

    @ViewChild(CustomBehaviourHostDirective) customBehaviours: CustomBehaviourHostDirective;

    @Input()
    gameId: string;

    @Input()
    entityType: string;

    sortValue$ = this.route.queryParams.pipe(map((val) => decodeURIComponent(val.sort || '')));

    constructor(
        private route: ActivatedRoute,
        public router: Router,
        private entityFormConfig: EntityFormConfig,
        private componentFactoryResolver: ComponentFactoryResolver
    ) {}

    ngAfterViewInit() {
        this.loadCustomFilter();
    }

    updateStructuredTextQuery(value) {
        const query = createQueryFilter(value) || '';
        this.filterEntities(query);
    }

    updateSort(sortValue) {
        snapshot(this.query$, (query) => {
            this.filterEntities(query, sortValue);
        });
    }

    filterEntities(value: string, sort = '') {
        snapshot(combineLatest([this.sortValue$, this.route.queryParams]), ([originalSortValue, originalQueryParams]) => {
            let queryParams: any = {
                ...originalQueryParams,
                query: ''
            };
            if (value) {
                queryParams.query = encodeURIComponent(value || '');
            }

            queryParams.sort = encodeURIComponent(sort || originalSortValue || 'name.en|asc');

            this.router.navigate(['.'], { relativeTo: this.route, queryParams });
        });
    }

    loadCustomFilter() {
        let gameId = this.route.snapshot.params.gameId;
        let entityType = this.route.snapshot.params.entityType;
        let component = this.entityFormConfig.entityFilters?.[gameId]?.[entityType];

        if (!component) {
            return;
        }

        const componentFactory = this.componentFactoryResolver.resolveComponentFactory<BaseEntityFilterComponent>(component);
        const viewContainerRef = this.customBehaviours.viewContainerRef;
        viewContainerRef.clear();
        const componentRef = viewContainerRef.createComponent(componentFactory);
        componentRef.instance.entityFilterChanged.subscribe((e) => this.updateStructuredTextQuery(e));
        componentRef.instance.entitySortChanged.subscribe((e) => this.updateSort(e));
    }
}
