import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray } from '@angular/forms';

import { BaseFormControl } from '../base-control';
import { FormItemPickerMultiField } from '../form-field';

@Component({
    selector: 'abs-item-select-multi',
    template: `
        <div class="form-group" *ngIf="parentFormGroup" [formGroup]="parentFormGroup">
            <label *ngIf="!field.hideLabel"
                >{{ field.label || (field.name | formLabel) }}
                <ion-icon class="info" *ngIf="field.description" name="information-circle" (click)="showDescription($event)"></ion-icon
            ></label>

            <input type="hidden" [formControlName]="i" *ngFor="let fc of formArray?.controls; let i = index" />

            <ng-template #itemTemplate let-item="item">
                <div class="itemName">
                    {{ item.label }}
                </div>
                <div class="itemCost">
                    <ion-button (click)="removeItem(item)">-</ion-button>
                    <span class="count">{{ getCount(item) }}</span>
                    <ion-button (click)="addItem(item)">+</ion-button>
                </div>
            </ng-template>
            <abs-list
                *ngIf="debouncedFormVal$ | async as debouncedFormVal"
                [template]="itemTemplate"
                [items]="this.field | absSelectOptions: debouncedFormVal : parentFormGroup | async"
            ></abs-list>
        </div>
    `,

    styles: [
        `
            .count {
                padding: 0 5px;
            }
        `
    ]
})
export class ItemSelectMultiComponent extends BaseFormControl {
    @Input()
    field: FormItemPickerMultiField;

    get formArray() {
        return this.parentFormGroup.get(this.field.name) as UntypedFormArray;
    }

    addItem(item) {
        const newValue = [...(this.formArray.value || []), item.value];
        this.formArray.patchValue(newValue);
    }

    removeItem(item) {
        if (!this.formArray.value.includes(item.value)) {
            return;
        }
        const newValue = [...(this.formArray.value || [])];

        newValue.splice(newValue.lastIndexOf(item.value), 1);
        this.formArray.patchValue(newValue);
    }

    getCount(item) {
        const count = this.formArray.value?.filter((x) => x === item.value).length || 0;
        return count;
    }
}
