import { getUniqueUnitId } from '../../../global';
import { Force } from '../../models';
import { FORCE_ACTIONS } from '../actions';
import { ForceState } from '../state';

export const duplicatePlatoonReducer = (state: ForceState, { forceId, platoonId }: ReturnType<typeof FORCE_ACTIONS.DUPLICATE_PLATOON>) => {
    const updatedState = structuredClone(state);
    const force = updatedState.forces.find((f) => f.id === forceId) as Force;
    const originalPlatoon = force.platoons.find((p) => p.id === platoonId);
    const newPlatoon = structuredClone(originalPlatoon);
    const platoonIds = force.platoons.map((p) => p.id);
    newPlatoon.id = Math.max(...platoonIds) + 1;

    const newUnits = structuredClone(force.units.filter((u) => u.platoonId === platoonId)).map((u) => {
        const id = getUniqueUnitId(force);
        return {
            ...u,
            id,
            platoonId: newPlatoon.id
        };
    });
    force.units.push(...newUnits);
    force.platoons.push(newPlatoon);

    return updatedState;
};
