import { CommonModule } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

import { MenuComponent } from './menu.component';

@NgModule({
    imports: [CommonModule, RouterModule, IonicModule, TranslateModule],
    exports: [MenuComponent],
    declarations: [MenuComponent],
    providers: [provideHttpClient(withInterceptorsFromDi())]
})
export class MenuModule {}
