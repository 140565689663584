import { Component, inject, NgZone } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, Platform } from '@ionic/angular';
import { Store } from '@ngrx/store';

import { InputAlert } from '../../../global/input-alert/input-alert';
import { ArmyBuilderConfig } from '../../../global/config';
import { Force } from '../../models';
import { snapshot } from '../../../global/utils';
import { ForceUtils } from '../../force.utils';
import { AlertService, DataLibrary, ForceSyncService, Modal, PurchasingService, SharingService } from '../../../global';
import { ForceService } from '../../force.service';

@Component({
    selector: 'abs-force-list',
    template: `
        <abs-page-layout [title]="'FORCES.HEADERS.FORCE_LIST' | translate" [displayBackLink]="true">
            <div class="buttons">
                <ion-button *ngIf="selectForceType$ | async; else addForceTemplate" [routerLink]="['new']">{{
                    'FORCES.BUTTONS.ADD_FORCE' | translate
                }}</ion-button>
                <ng-template #addForceTemplate>
                    <ion-button (click)="addForce()">{{ 'FORCES.BUTTONS.ADD_FORCE' | translate }}</ion-button>
                </ng-template>
            </div>
            <ng-template #forceRowTemplate let-item="item">
                <div class="itemName">
                    {{ item.name }}
                </div>
                <div class="itemCost">{{ item.cost }}{{ 'GLOBAL.PTS' | translate }}</div>
            </ng-template>
            <abs-list
                [items]="forces$ | async"
                [getRouterLink]="getRouterLink"
                [showDelete]="true"
                (deleteItem)="deleteForce($event.item)"
                [template]="forceRowTemplate"
            ></abs-list>
        </abs-page-layout>
    `
})
export class ForceListPage {
    public forceService = inject(ForceService);
    public inputAlert = inject(InputAlert);
    public alertController = inject(AlertController);
    public config = inject(ArmyBuilderConfig);
    public translateService = inject(TranslateService);
    public dataLibrary = inject(DataLibrary);
    public forceUtils = inject(ForceUtils);
    public route = inject(ActivatedRoute);
    public router = inject(Router);
    public modal = inject(Modal);
    public platform = inject(Platform);
    public sharingService = inject(SharingService);
    public store = inject(Store);
    public zone = inject(NgZone);
    public syncService = inject(ForceSyncService);
    public purchasingService = inject(PurchasingService);
    public alert = inject(AlertService);

    selectForceType$ = this.route.data.pipe(map((d) => d.selectForceType));

    forces$ = combineLatest([this.forceService.gameId$, this.forceService.forces$]).pipe(
        map(([gameId, forces]) => forces.filter((f) => f.gameId === gameId))
    );

    addForce() {
        snapshot(
            combineLatest([
                this.translateService.get('FORCES.DIALOGS.NEW_FORCE.TITLE'),
                this.translateService.get('FORCES.DIALOGS.NEW_FORCE.MESSAGE'),
                this.translateService.get('FORCES.DIALOGS.NEW_FORCE.DEFAULT_FORCE_NAME')
            ]),
            ([title, message, defaultForceName]) => {
                this.inputAlert.show(title, message, defaultForceName, 'text', true).then((name) => {
                    if (!name) {
                        return;
                    }
                    const f: Force = {
                        id: undefined,
                        gameId: null,
                        faction: 'test',
                        name,
                        units: [],
                        cost: 0,
                        platoons: []
                    };
                    this.forceService.add(f);
                });
            }
        );
    }

    getRouterLink(force: Force) {
        return [force.id];
    }

    deleteForce(force: Force) {
        this.forceService.delete(force.id);
    }
}
