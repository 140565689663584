import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Chart, registerables } from 'chart.js';
import { UserService } from '../../global';

@Component({
    selector: 'abs-analytics',
    template: `
        <abs-page-layout title="Analytics">
            <canvas #barCanvas style="position: relative; height:400px; width:100%"></canvas>
            <div class="buttons">
                <ion-button [fill]="period === 0.5 ? 'outline' : 'solid'" (click)="regenerateBarchart(0.5)">30 min</ion-button>
                <ion-button [fill]="period === 24 ? 'outline' : 'solid'" (click)="regenerateBarchart(24)">1 day</ion-button>
                <ion-button [fill]="period === 24 * 30 ? 'outline' : 'solid'" (click)="regenerateBarchart(24 * 30)">30 day</ion-button>
                <ion-button [fill]="period === 24 * 365 ? 'outline' : 'solid'" (click)="regenerateBarchart(24 * 365)">365 day</ion-button>
            </div>
        </abs-page-layout>
    `
})
export class AnalyticsPage implements OnInit, OnDestroy {
    @ViewChild('barCanvas') private barCanvas: ElementRef;
    constructor(private userService: UserService) {}

    barChart: any;
    period = 0.5;
    timer: any;

    async ngOnInit() {
        this.regenerateBarchart(0.5);
    }

    async regenerateBarchart(threshold: number) {
        if (this.timer) {
            clearInterval(this.timer);
        }
        this.period = threshold;
        this.timer = setInterval(() => this.updateData(), 60000);
        this.updateData();
    }

    async updateData() {
        console.log('Updating analytics data');
        let stats = await this.userService.getStats(this.period);
        if (this.barChart) {
            this.barChart.data.labels = stats.labels;
            // this.barChart.data.datasets[0].data = stats.eventCount;
            this.barChart.data.datasets[0].data = stats.userCount;
            this.barChart.update();
        } else {
            this.createChart(stats);
        }
    }

    createChart(stats) {
        let self = this;
        Chart.register(...registerables);
        this.barChart = new Chart(this.barCanvas.nativeElement, {
            type: 'bar',
            data: {
                labels: stats.labels,
                datasets: [
                    // {
                    //     type: 'bar',
                    //     label: '# of Events',
                    //     data: stats.eventCount,
                    //     borderWidth: 0,
                    //     backgroundColor: '#C00',
                    //     yAxisID: 'eventAxis'
                    // },
                    {
                        type: 'bar',
                        label: '# of Active Users',
                        data: stats.userCount,
                        borderWidth: 0,
                        backgroundColor: '#006',
                        yAxisID: 'userAxis'
                    }
                ]
            },
            options: {
                scales: {
                    // eventAxis: {
                    //     position: 'right',
                    //     suggestedMax: 25
                    // },
                    userAxis: {
                        position: 'left',
                        suggestedMax: 10
                    },
                    x: {
                        ticks: {
                            callback: function (value: number) {
                                let suffix = 'm';

                                if (self.period > 1 && self.period <= 24) {
                                    suffix = 'h';
                                } else if (self.period > 24 * 30) {
                                    suffix = 'w';
                                } else if (self.period > 24) {
                                    suffix = 'd';
                                }
                                return this.getLabelForValue(value) + suffix;
                            }
                        }
                    }
                }
            }
        });
    }

    ngOnDestroy(): void {
        if (this.timer) {
            clearInterval(this.timer);
        }
    }
}
