import { createReducer, on, Action } from '@ngrx/store';

import {
    markEntityAsDirty,
    entitySyncComplete,
    startEntitySync,
    entitySyncError,
    entityChangeDataLoaded,
    entityChangeTimeoutCheck,
    removeEntityFromChangeCache,
    markEntityAsDeleted
} from './actions';

export type SyncStatus = 'InProgress' | 'Dirty' | 'Clean' | 'Error' | 'Deleted';

export interface EntityChangeState {
    [entityTypeKey: string]:
        | SyncStatus
        | {
              [entityId: string]: SyncStatus;
          };
}

export const initialChangedEntitiesState: EntityChangeState = {};

const getNewEntityStatus = (state: EntityChangeState, syncStatus: SyncStatus, entityType: string, entityId?: any) => {
    if (!entityId) {
        return syncStatus;
    }

    const status = {
        ...((state[entityType] as object) || {}),
        [entityId]: syncStatus
    };

    return status;
};

export function changedEntitiesReducer(state: EntityChangeState | undefined, action: Action) {
    return createReducer(
        initialChangedEntitiesState,
        on(entityChangeDataLoaded, (_oldState: EntityChangeState, newState: EntityChangeState) => {
            newState = JSON.parse(JSON.stringify(newState));
            delete newState.type;

            const entityTypeKeys = Object.keys(newState);
            for (const entityTypeKey of entityTypeKeys) {
                if (typeof newState[entityTypeKey] === 'object') {
                    const entityIds = Object.keys(newState[entityTypeKey]);
                    for (const entityId of entityIds) {
                        if (newState[entityTypeKey][entityId] === 'InProgress') {
                            newState[entityTypeKey][entityId] = 'Error';
                        }
                    }
                } else if (newState[entityTypeKey] === 'InProgress') {
                    newState[entityTypeKey] = 'Error';
                }
            }
            return newState;
        }),
        on(markEntityAsDirty, (state: EntityChangeState, { entityType, entityId }) => ({
            ...state,
            [entityType]: getNewEntityStatus(state, 'Dirty', entityType, entityId)
        })),
        on(markEntityAsDeleted, (state: EntityChangeState, { entityType, entityId }) => ({
            ...state,
            [entityType]: getNewEntityStatus(state, 'Deleted', entityType, entityId)
        })),
        on(startEntitySync, (state: EntityChangeState, { entityType, entityId }) => ({
            ...state,
            [entityType]: getNewEntityStatus(state, 'InProgress', entityType, entityId)
        })),
        on(entitySyncComplete, (state: EntityChangeState, { entityType, entityId }) => ({
            ...state,
            [entityType]: getNewEntityStatus(state, 'Clean', entityType, entityId)
        })),
        on(entitySyncError, (state: EntityChangeState, { entityType, entityId }) => ({
            ...state,
            [entityType]: getNewEntityStatus(state, 'Error', entityType, entityId)
        })),
        on(entityChangeTimeoutCheck, (state: EntityChangeState, { entityType, entityId }) => {
            const currentState = state[entityType]?.[entityId];

            if (!currentState) {
                // This can occur if the user logs out before the timeout completes
                return state;
            }

            return {
                ...state,
                [entityType]: getNewEntityStatus(state, currentState === 'InProgress' ? 'Error' : currentState, entityType, entityId)
            };
        }),
        on(removeEntityFromChangeCache, (state: EntityChangeState, { entityType, entityId }) => {
            const newState = {
                ...state
            };

            if (entityId) {
                newState[entityType] = {
                    ...((state[entityType] as object) || {})
                };
                delete newState[entityType][entityId];
            } else {
                delete newState[entityType];
            }

            return newState;
        })
    )(state, action);
}
