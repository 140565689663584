<div class="section">
    <h2 *ngIf="field.header">{{ field.header }}</h2>
    <label *ngIf="field.label">{{ field.label }}</label>
    <p *ngIf="field.description && !field.tooltip" [innerHTML]="field.description"></p>
    <ion-icon
        name="information-circle"
        *ngIf="field.description && field.tooltip"
        (click)="showDescription($event)"
    ></ion-icon>
    <div [class]="field.cssClass">
        <abs-universal-field
            *ngFor="let sectionField of field.fields"
            [field]="sectionField"
            [formValue]="formValue"
        ></abs-universal-field>
    </div>
</div>
