import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntypedFormArray } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { createForm } from '../../config';
import { BaseFormArrayComponent } from '../form-array/form-array.component';

@Component({ template: '', changeDetection: ChangeDetectionStrategy.OnPush })
export class BaseComplexTagsComponent extends BaseFormArrayComponent {
    @Input()
    field: any;

    value: string = '';
    fixNumericalIds = false;

    getNewValue(value: string) {
        let opt: any = this.field.options?.find((o: any) => o === value || o.label === value);
        let id = opt?.value || opt || value;
        return { id };
    }

    async showModal(value, i) {
        console.log('Show modal', value, i);
        // let tagsFormGroup = (this.parentFormGroup.get(this.field.name) as FormArray).at(i);
        // let modal = await this.modalController.create({
        //     component: TranslationModalComponent,
        //     componentProps: {
        //         value,
        //         parentFormGroup: tagsFormGroup
        //     }
        // });
        // modal.onDidDismiss().then((data) => {
        //     console.log('Modal dismissed', data);
        //     tagsFormGroup.patchValue(data);
        // });
        // modal.present();
    }

    options$ = new BehaviorSubject([]);

    get formArray() {
        return this.parentFormGroup.get(this.field.name) as UntypedFormArray;
    }

    onEnter(e) {
        let val = e.srcElement.value.trim();
        if (!val || this.formArray.value.includes(val)) {
            return;
        }
        this.addItem(val);
        e.target.focus();
    }

    addItem(val) {
        let newValue = [...this.formArray.value, this.getNewValue(val)];
        this.formArray.push(createForm(this.field.fields));
        this.formArray.patchValue(newValue);
        this.value = '';
    }

    removeItem(e: any, index: number) {
        e.preventDefault();
        e.stopPropagation();
        this.formArray.removeAt(index);
    }

    updateValue(e) {
        this.value = e.target.value || '';
    }

    selectionUpdate(e) {
        this.value = e || '';
    }

    commitSelection(val) {
        this.addItem(val);
    }
}
