import { Component, TemplateRef, ViewChild } from '@angular/core';
import { IonInput } from '@ionic/angular';
import { SettingsService, DataSyncService, Modal } from 'army-builder-shared';
import { TranslateService } from '@ngx-translate/core';

import { WarlordUserService } from './user.service';

@Component({
    selector: 'wl-user',
    template: `
        <div>
            <ng-template #deleteAccountTemplate>
                <p>{{ 'GLOBAL.SETTINGS.ACCOUNT.DELETE.ARE_YOU_SURE' | translate }}</p>
            </ng-template>
            <ng-template #deleteAccountButtonsTemplate>
                <ion-button (click)="deleteAccount()">{{ 'GLOBAL.YES' | translate }}</ion-button>
                <ion-button (click)="modal.dismiss()">{{ 'GLOBAL.NO' | translate }}</ion-button>
            </ng-template>
            <div class="buttons">
                <ion-button (click)="userService.logout()">
                    {{ 'GLOBAL.LOGIN.BUTTONS.LOGOUT' | translate }}
                </ion-button>
                <ion-button [color]="'danger'" (click)="deleteAccountConfirm()">{{
                    'GLOBAL.SETTINGS.ACCOUNT.DELETE.BUTTON' | translate
                }}</ion-button>
            </div>
        </div>
    `
})
export class UserPage {
    @ViewChild('username')
    username: IonInput;

    @ViewChild('password')
    password: IonInput;

    showRegister = true;

    @ViewChild('deleteAccountTemplate')
    deleteAccountTemplate: TemplateRef<any>;

    @ViewChild('deleteAccountButtonsTemplate')
    deleteAccountButtonsTemplate: TemplateRef<any>;

    constructor(
        public settingsService: SettingsService,
        public userService: WarlordUserService,
        private translateService: TranslateService,
        private syncService: DataSyncService,
        public modal: Modal
    ) {}

    logout() {
        this.userService.logout();
    }

    sync() {
        this.syncService.sync();
    }

    deleteAccountConfirm() {
        this.modal.show({
            props: {
                contentTemplate: this.deleteAccountTemplate,
                buttonsTemplate: this.deleteAccountButtonsTemplate,
                title: this.translateService.instant('GLOBAL.SETTINGS.ACCOUNT.DELETE.TITLE')
            }
        });
    }

    deleteAccount() {
        this.userService.deleteAccount();
    }
}
