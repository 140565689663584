import { createAction, props } from '@ngrx/store';

import { EntityChangeState } from './reducer';

export const DATA_ACTIONS = {
    markEntityAsDirty: '[Army Builder Data] markEntityAsDirty',
    markEntityAsDeleted: '[Army Builder Data] markEntityAsDeleted',
    startEntitySync: '[Army Builder Data] startEntitySync',
    entitySyncComplete: '[Army Builder Data] entitySyncComplete',
    entitySyncError: '[Army Builder Data] entitySyncError',
    loadEntityChangeDataFromCache: '[Army Builder Data] loadEntityChangeDataFromCache',
    entityChangeDataLoaded: '[Army Builder Data] entityChangeDataLoaded',
    entityChangeTimeoutCheck: '[Army Builder Data] entityChangeTimeoutCheck',
    removeEntityFromChangeCache: '[Army Builder Data] removeEntityFromChangeCache',
    resetAppState: '[Army Builder Data] resetAppState'
};

export interface EntityChangePayload {
    entityType: string;
    entityId?: string;
    updatedAt?: number; // Serves as a way to detect if a local entity change cache should be superceded by changes on the server
}

export const markEntityAsDirty = createAction(DATA_ACTIONS.markEntityAsDirty, props<EntityChangePayload>());
export const markEntityAsDeleted = createAction(DATA_ACTIONS.markEntityAsDeleted, props<EntityChangePayload>());
export const startEntitySync = createAction(DATA_ACTIONS.startEntitySync, props<EntityChangePayload>());
export const entitySyncComplete = createAction(DATA_ACTIONS.entitySyncComplete, props<EntityChangePayload>());
export const entitySyncError = createAction(DATA_ACTIONS.entitySyncError, props<EntityChangePayload>());
export const loadEntityChangeDataFromCache = createAction(DATA_ACTIONS.loadEntityChangeDataFromCache);
export const entityChangeTimeoutCheck = createAction(DATA_ACTIONS.entityChangeTimeoutCheck, props<EntityChangePayload>());
export const entityChangeDataLoaded = createAction(DATA_ACTIONS.entityChangeDataLoaded, props<EntityChangeState>());
export const removeEntityFromChangeCache = createAction(DATA_ACTIONS.removeEntityFromChangeCache, props<EntityChangePayload>());
export const resetAppState = createAction(DATA_ACTIONS.resetAppState);
