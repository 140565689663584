import { NgModule } from '@angular/core';
import { ArmyBuilderBattleModule } from '../battle/battle.module';
import { PageLayoutModule } from './page-layout';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { CustomLoader } from './i18n';
import { LanguageModule } from './language';
import { AppInjectorModule } from './app.injector';
import { ModalLayoutComponent, ModalLayoutModule } from './modal-layout';
import { HttpClientWithInFlightCache } from './httpClient';
import { PipesAndDirectivesModule } from './pipes';

@NgModule({
    imports: [
        PageLayoutModule,
        ModalLayoutModule,
        // ArmyBuilderForceModule,
        ArmyBuilderBattleModule,
        LanguageModule,
        TranslateModule.forRoot({
            defaultLanguage: 'en',
            loader: {
                provide: TranslateLoader,
                useClass: CustomLoader,
                deps: [HttpClientWithInFlightCache]
            }
        }),
        AppInjectorModule,
        PageLayoutModule,
        PipesAndDirectivesModule
    ],
    providers: [],
    exports: [ModalLayoutComponent],
    declarations: []
})
export class ArmyBuilderSharedModule {}
