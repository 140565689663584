import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { combineLatest } from 'rxjs/internal/observable/combineLatest';
import { ActivatedRoute } from '@angular/router';

import { Modal } from '../global/modal';
import { NativeStorageService } from '../global';

import { SupportService } from './support.service';
import { IssueDetailComponent } from './issue-detail.component';
import { IssueStatus, SupportMode } from './model';

@Component({
    selector: 'abs-support-issues',
    template: `<abs-page-layout [title]="'Support Issues'">
        <ng-template #itemTemplate let-item="item">
            <abs-issue-row [issue]="item" (dismiss)="onDismiss($event)" [mode]="this.mode"></abs-issue-row>
        </ng-template>

        <div class="filters" *ngLet="filterIndex$ | async as filterIndex">
            <div class="impersonate" *ngIf="mode === 'admin'">
                <p>Enter a user's email address to view their lists in your "Shared Forces" section.</p>
                <p><ion-input [(ngModel)]="impersonate" (ionInput)="impersonateEmailUpdated($event)" /></p>
            </div>
            <ion-button [size]="'small'" [shape]="filterIndex !== 0 ? 'outline' : null" [color]="'success'" (click)="filterByIssueStatus(0)"
                >Open Issues</ion-button
            >
            <ion-button [size]="'small'" [shape]="filterIndex !== 1 ? 'outline' : null" [color]="'danger'" (click)="filterByIssueStatus(1)"
                >Closed Issues</ion-button
            >
        </div>
        <div class="dateRange" *ngLet="numDays$ | async as numDays">
            <ion-button [size]="'small'" [shape]="numDays !== 7 ? 'outline' : null" (click)="filterByNumDays(7)">7 days</ion-button>
            <ion-button [size]="'small'" [shape]="numDays !== 28 ? 'outline' : null" (click)="filterByNumDays(28)">28 days</ion-button>
            <ion-button [size]="'small'" [shape]="numDays !== 90 ? 'outline' : null" (click)="filterByNumDays(90)">90 days</ion-button>
            <ion-button [size]="'small'" [shape]="numDays !== 180 ? 'outline' : null" (click)="filterByNumDays(180)">180 days</ion-button>
            <ion-button [size]="'small'" [shape]="numDays !== 180 ? 'outline' : null" (click)="filterByNumDays(-1)">All Time</ion-button>
        </div>

        <abs-list
            [items]="issues$ | async"
            [template]="itemTemplate"
            (itemSelect)="showIssueDetail($event.item)"
            [activeStyleField]="'unread'"
        ></abs-list>
    </abs-page-layout>`,
    styles: [
        `
            .filters {
                padding: 10px 0 0;
            }
        `
    ]
})
export class SupportIssuesPage implements OnDestroy, OnInit {
    mode: SupportMode = 'admin';
    filters: IssueStatus[][] = [
        ['New', 'In progress', 'Customer responded', 'Waiting for customer'],
        ['Resolved', 'Rejected']
    ];

    impersonate: string;

    unreadCount$ = this.supportService.unreadCount$;
    numDays$ = new BehaviorSubject<number>(28);
    filterIndex$ = new BehaviorSubject<number>(0);
    issues$ = combineLatest([this.supportService.allIssues$, this.filterIndex$]).pipe(
        map(([issues, filterIndex]) => {
            if (issues) {
                return issues
                    .filter((i) => this.filters[filterIndex].includes(i.status))
                    .sort((a, b) => {
                        const aDate = a.updates.length > 0 ? a.updates[a.updates.length - 1].updateDate : a.createdAt;
                        const bDate = b.updates.length > 0 ? b.updates[b.updates.length - 1].updateDate : b.createdAt;

                        return new Date(bDate).getTime() - new Date(aDate).getTime();
                    })
                    .map((issue) => {
                        if (this.mode === 'admin' && (issue.status === 'Customer responded' || issue.status === 'New')) {
                            return { ...issue, unread: true };
                        }
                        return issue;
                    });
            }

            return [];
        })
    );

    loadIssuesSub = combineLatest([this.filterIndex$, this.numDays$]).subscribe(([filterIndex, numDays]) => {
        this.supportService.loadIssues(this.filters[filterIndex], numDays);
    });

    constructor(
        private supportService: SupportService,
        private modal: Modal,
        private route: ActivatedRoute,
        private storageService: NativeStorageService
    ) {
        this.filterByIssueStatus(0);
    }

    async ngOnInit() {
        this.mode = this.route.snapshot.data.mode;
        this.impersonate = await this.storageService.getItem('impersonate', '');
    }

    showIssueDetail(issue: any) {
        this.modal.show({
            component: IssueDetailComponent,
            props: { issueId: issue._id, onDismiss: this.onDismiss, mode: this.mode } as any
        });
    }

    onDismiss(res: any) {}

    filterByIssueStatus(filterIndex: number) {
        this.filterIndex$.next(filterIndex);
    }

    filterByNumDays(numDays: number) {
        this.numDays$.next(numDays);
    }

    ngOnDestroy() {
        if (this.loadIssuesSub) {
            this.loadIssuesSub.unsubscribe();
        }
    }

    impersonateEmailUpdated(e) {
        const email = e?.detail?.value;
        this.storageService.setItem('impersonate', email);
    }
}
