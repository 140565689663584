import { createReducer, on, Action } from '@ngrx/store';

import { LoginState } from '../models';
import { markLoginChecked } from '../..';

import { selectLanguage, updateSettingsState, updateSettingsValue } from './actions';

export interface SettingsState {
    global: {
        login: LoginState;
        selectedLanguage: string;
    };
}

export const defaultLoginState = {
    user: null,
    error: null,
    loading: false,
    checked: false
};

export const initialSettingsState: SettingsState = {
    global: {
        login: defaultLoginState,
        selectedLanguage: 'en'
    }
};

export function settingsReducer(state: SettingsState | undefined, action: Action) {
    return createReducer(
        initialSettingsState,
        on(updateSettingsValue, (state: SettingsState, { gameId, settingsKey, newValue }) => {
            const updatedState = structuredClone(state);
            let gameSettings = updatedState[gameId];
            if (!gameSettings) {
                gameSettings = {};
            }
            gameSettings[settingsKey] = typeof newValue === 'object' ? { ...state[settingsKey], ...newValue } : newValue;
            updatedState[gameId] = gameSettings;
            return updatedState;
        }),
        on(updateSettingsState, (state: any, newState: SettingsState) => ({
            ...state,
            ...newState,
            global: {
                ...state.global,
                ...newState.global
            }
        })),
        on(selectLanguage, (state: any, { language }) => ({
            ...state,
            global: {
                ...state.global,
                selectedLanguage: language
            }
        })),
        on(markLoginChecked, (state: any, { checked }) => ({
            ...state,
            global: {
                ...state.global,
                login: {
                    ...state.global.login,
                    checked
                }
            }
        }))
    )(state, action);
}
