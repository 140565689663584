import { Component, ElementRef, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Color } from '@ionic/core';
import { IonInput, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Force } from '../../models';
import { snapshot, validateEmail } from '../../../global/utils';
import { ForceService } from '../../force.service';
import { QRCodeService } from '../../../global/qr-code-service';
import { Modal } from '../../../global/modal';
import { ArmyBuilderConfig } from '../../../global';

@Component({
    selector: 'abs-share-force',
    template: `
        <div class="shareForce" *ngIf="force$ | async as force">
            <div *ngIf="config.enableQRCode" class="qrCode">
                <h2>{{ 'GLOBAL.SHARE.QR_CODE.TITLE' | translate }}</h2>
                <ng-template #qrCodeTemplate>
                    <canvas #qrCode></canvas>
                </ng-template>
                <p>{{ 'GLOBAL.SHARE.QR_CODE.DESCRIPTION' | translate }}</p>
                <p>
                    <ion-button (click)="shareViaQRCode()">{{ 'GLOBAL.SHARE.QR_CODE.BUTTON' | translate }}</ion-button>
                </p>
                <hr />
            </div>

            <h2>{{ 'GLOBAL.SHARE.URL.TITLE' | translate }}</h2>
            <p>{{ 'GLOBAL.SHARE.URL.DESCRIPTION' | translate }}</p>
            <ion-list>
                <div class="row">
                    <ion-item>
                        <ion-label>{{ 'GLOBAL.SHARE.URL.TOGGLE' | translate }}</ion-label>
                        <ion-toggle [checked]="force.shareViaURL" (ionChange)="toggleShareViaURL()"></ion-toggle>
                    </ion-item>
                </div>
            </ion-list>
            <ng-container *ngIf="force.shareViaURL">
                <!-- <p>{{ 'GLOBAL.SHARE.URL.DESCRIPTION_DETAIL' | translate }}</p> -->
                <p class="form-row">
                    <ion-icon name="globe-outline"></ion-icon>
                    <ion-input #shareWebUrl [contentEditable]="false" readonly [value]="shareUrlPrefix + force.id"></ion-input>
                    <ion-button (click)="copyShareUrl()"><ion-icon name="copy-outline"></ion-icon></ion-button>
                </p>

                <p class="form-row">
                    <ion-icon name="text-outline"></ion-icon>
                    <ion-input #shareCode [contentEditable]="false" readonly [value]="shareCode$ | async"></ion-input>
                    <ion-button (click)="copyShareCode()"><ion-icon name="copy-outline"></ion-icon></ion-button>
                </p>
            </ng-container>
            <hr />
            <h2>{{ 'GLOBAL.SHARE.EMAIL.TITLE' | translate }}</h2>
            <p>{{ 'GLOBAL.SHARE.EMAIL.DESCRIPTION' | translate }}</p>
            <div class="form-row">
                <ion-input #emailAddress (keydown.Enter)="addShare($event)"></ion-input>
                <ion-button (click)="addShare($event)"><ion-icon name="add-outline"></ion-icon></ion-button>
            </div>
            <div class="validation error" *ngIf="invalidEmail">
                {{ 'GLOBAL.SHARE.EMAIL.ERRORS.INVALID_EMAIL' | translate }}
            </div>
            <div class="validation error" *ngIf="duplicateEmail">
                {{ 'GLOBAL.SHARE.EMAIL.ERRORS.DUPLICATE_EMAIL' | translate }}
            </div>
            <ng-template #shareTemplate let-item="item">
                <div class="emailRow">
                    <span class="email">{{ item.email }}</span>
                    <ion-icon slot="end" name="close-outline" (click)="removeShare(item.email)"></ion-icon>
                </div>
            </ng-template>
            <abs-list
                [items]="force.sharedWith || []"
                [noItemsTextKey]="'GLOBAL.SHARE.EMAIL.NONE'"
                [template]="shareTemplate"
                [showDelete]="false"
            ></abs-list>
        </div>
    `,
    styles: [
        `
            .form-row {
                display: flex;
                align-items: stretch;
            }

            .form-row ion-input {
                flex: 1 1 auto;
            }

            .form-row > ion-icon {
                flex: 1 0 50px;
                align-self: center;
            }

            .form-row ion-button {
                margin: 0 0 0 5px;
                height: auto !important;
            }

            .emailRow {
                display: flex;
                width: 100%;
            }

            .emailRow ion-icon {
                flex: 0 0 16px;
                align-self: center;
            }

            .emailRow .email {
                flex: 1 1 auto;
            }

            canvas {
                margin: 0 auto;
                display: block;
            }
        `
    ]
})
export class ShareForceComponent implements OnInit {
    @Input()
    force$: Observable<Force>;

    @Input()
    shareUrlPrefix: string;

    @Input()
    gameId: string;

    @ViewChild('qrCode')
    canvas: ElementRef;

    invalidEmail = false;
    duplicateEmail = false;
    shareCode$: Observable<string>;

    @ViewChild('emailAddress')
    emailAddress: IonInput;

    @ViewChild('qrCodeTemplate')
    qrCodeTemplate: TemplateRef<any>;

    constructor(
        private translateService: TranslateService,
        private toastController: ToastController,
        private router: Router,
        private route: ActivatedRoute,
        private qrCodeService: QRCodeService,
        private modal: Modal,
        private forceService: ForceService, // private clipboard: Clipboard,
        public config: ArmyBuilderConfig
    ) {}

    ngOnInit(): void {
        this.shareCode$ = this.force$.pipe(map((f) => parseInt(f.id).toString(36).toUpperCase()));
    }

    async copyShareUrl() {
        snapshot(this.force$, async (force) => {
            const shareUrl = this.shareUrlPrefix + force.id;
            this.copyToClipboard(shareUrl, 'GLOBAL.SHARE.URL.COPIED');
        });
    }

    async copyShareCode() {
        snapshot(this.shareCode$, async (code) => {
            this.copyToClipboard(code, 'GLOBAL.SHARE.URL.COPIED_CODE');
        });
    }

    async copyToClipboard(value: string, successMessage: string) {
        try {
            // await this.clipboard.copy(value);
            await navigator.clipboard.writeText(value);
            this.showToast(successMessage);
        } catch (err) {
            console.error(err);
            this.showToast('GLOBAL.SHARE.URL.COPY_ERROR', 'danger');
        }
    }

    generateQRCode() {
        snapshot(this.force$, (force) => {
            this.qrCodeService.generate(this.canvas, force);

            // QRCode.toCanvas(this.canvas.nativeElement, JSON.stringify(shareableForce), function (error) {
            //     if (error) {
            //         console.error(error);
            //     }
            // });
        });
    }

    async addShare(e) {
        snapshot(this.force$, (force) => {
            const emailAddress = ('' + this.emailAddress.value).toLowerCase().trim();
            const updatedForce = { ...force };
            if (!updatedForce.sharedWith) {
                updatedForce.sharedWith = [];
            }

            this.invalidEmail = false;
            this.duplicateEmail = false;

            if (updatedForce.sharedWith.find((x) => x.email === emailAddress)) {
                this.duplicateEmail = true;
                return;
            }

            if (!validateEmail(emailAddress)) {
                this.invalidEmail = true;
                return;
            }

            updatedForce.sharedWith = [...updatedForce.sharedWith, { email: emailAddress }];

            this.forceService.upsert(updatedForce);

            this.emailAddress.value = '';
            this.emailAddress.setFocus();
        });
    }

    removeShare(email: string) {
        snapshot(this.force$, (force) => {
            const updatedForce = { ...force };
            updatedForce.sharedWith = updatedForce.sharedWith.filter((x) => x.email !== email);

            this.forceService.upsert(updatedForce);
            this.showToast(this.translateService.instant('GLOBAL.SHARE.EMAIL.REMOVED'));
        });
    }

    toggleShareViaURL() {
        snapshot(this.force$, (force) => {
            const updatedForce = { ...force, shareViaURL: !force.shareViaURL };
            this.forceService.upsert(updatedForce);
        });
    }

    shareViaQRCode() {
        this.modal.dismiss();
        this.modal
            .show({
                props: {
                    contentTemplate: this.qrCodeTemplate,
                    title: this.translateService.instant('GLOBAL.SHARE.QR_CODE.TITLE')
                }
            })
            .then(() => {
                this.generateQRCode();
            });
    }

    async showToast(msg: string, type: Color = 'success') {
        const toast = await this.toastController.create({
            message: this.translateService.instant(msg),
            color: type,
            duration: 3000
        });
        toast.present();
    }
}
