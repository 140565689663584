import { snapshot } from 'army-builder-shared';
import { Purchases } from '@revenuecat/purchases-js';
import { firstValueFrom } from 'rxjs';

import { PurchasingService } from './purchasing.service';
import { AddProduct, AddPurchase, selectProducts, UpdateProduct } from './state';

export class PurchasingServiceWeb extends PurchasingService {
    async configurePlatform(userId: string) {
        //
        try {
            // const stripeProducts = await firstValueFrom(this.http.get(this.config.apiBaseUrl + '/purchase/products'));
            // const products = await firstValueFrom(this.store.select(selectProducts));
            // products.forEach((product) => {
            //     const price = stripeProducts.find((p) => p.price?.id === product.stripeKeyProd || p.price?.id === product.stripeKeyStaging);
            //     if (!price) {
            //         return;
            //     }
            //     this.store.dispatch(
            //         UpdateProduct({
            //             product: {
            //                 ...product,
            //                 price: price.displayPrice
            //             }
            //         })
            //     );
            // });
            // console.log({ products });
        } catch (e) {
            // Handle errors
        }
    }

    async purchase(productId) {
        console.log('Purchasing: ' + productId);
        // if (!this.platform.is('cordova')) {

        snapshot(this.products$, (products) => {
            if (this.config.enableStripePurchases) {
                const product = products.find((p) => p.key === productId);
                const priceId = this.config.environment === 'staging' ? product.stripeKeyStaging : product.stripeKeyProd;

                this.http
                    .post(
                        this.config.apiBaseUrl + '/purchase/createCheckoutSession/',
                        {
                            priceId
                        },
                        {
                            headers: this.config.globalRequestHeaders,
                            withCredentials: true
                        }
                    )
                    .subscribe((res) => {
                        console.log(res);
                        // TODO: does this need a check of some sort?
                        // this.addPurchase(productId);
                    });
            } else {
                console.log('Purchases not supported on this platform.');
                this.store.dispatch(AddPurchase({ productId }));
            }
        });
        return;
        // }
        // this.store.dispatch(SetProductsStatus({ productId, status: 'loading' }));
        // snapshot(this.products$, (products) => {
        //     const pkg = products.find((p) => p.key === productId).package;
        //     this.debug.log('Ordering From Store: ' + productId, JSON.stringify(pkg));
        //     this.purchases
        //         .purchasePackage(pkg)
        //         .then(({ productIdentifier, customerInfo }) => {
        //             this.debug.log(JSON.stringify({ productId, pkg, productIdentifier, customerInfo }));
        //             if (typeof customerInfo.entitlements.active[productId] !== 'undefined') {
        //                 this.addPurchase(productId);
        //                 this.debug.log(`${productId} added`);
        //             }
        //         })
        //         .catch(({ error, userCancelled }) => {
        //             // Error making purchase
        //             console.log(error, userCancelled);
        //             this.debug.log('Error during purchase: ' + JSON.stringify({ error, userCancelled }));
        //         });
        // });
    }

    loadProductsForPlatform() {
        return [];
    }
}
