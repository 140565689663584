import { AfterViewInit, Component, EventEmitter, Output, TemplateRef, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'abs-base-entity-row',
    template: ``
})
export class BaseEntityRowComponent implements AfterViewInit {
    rowTemplate$ = new BehaviorSubject<TemplateRef<any>>(null);
    @ViewChild('rowTemplate') public rowTemplate: TemplateRef<any>;

    ngAfterViewInit() {
        this.rowTemplate$.next(this.rowTemplate);
    }
}
