import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { AnalyticsModule } from './analytics';

import { FormControlsModule } from './form-controls/form-controls.module';
import { SupportModule } from '../support';

@NgModule({
    imports: [CommonModule, IonicModule, FormControlsModule, AnalyticsModule, SupportModule],
    exports: [],
    declarations: [],
    providers: []
})
export class AdminModule {}
