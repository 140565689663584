import { createFeatureSelector, createSelector } from '@ngrx/store';

import { EntityChangeState, SyncStatus } from './reducer';

export const EntityChangeSelector = (s) => s.entityChange as EntityChangeState;
export const EntityChangeStateByType = createSelector(
    EntityChangeSelector,
    (state: EntityChangeState, entityType: string) => state[entityType]
);

export const SelectSyncStatus = createSelector(
    EntityChangeSelector,
    (state: EntityChangeState, props: { entityType: string; id?: string }) => {
        const { entityType, id } = props;
        if (!id) {
            return state[entityType];
        }
        return state[entityType]?.[id] || 'Clean';
    }
);

export const IsForceClean = createSelector(SelectSyncStatus, (state: SyncStatus) => state === 'Clean');
export const IsForceDirty = createSelector(SelectSyncStatus, (state: SyncStatus) => state === 'Dirty');
export const IsForceSyncing = createSelector(SelectSyncStatus, (state: SyncStatus) => state === 'InProgress');
export const IsForceSyncErrored = createSelector(SelectSyncStatus, (state: SyncStatus) => state === 'Error');
