import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { SupportIssue, SupportMode } from './model';

@Component({
    selector: 'abs-issue-row',
    template: `
        <abs-issue-status [status]="issue.status"></abs-issue-status>
        <div class="body">
            <div class="page">{{ issue.url }}</div>
            <div class="description">
                {{ issue.description }}
            </div>
            <div class="created">
                Created <abs-relative-date [date]="issue.createdAt"></abs-relative-date> by
                {{ issue.userData.user.name }}
            </div>
            <div class="updated">Last updated <abs-relative-date [date]="updatedAt"></abs-relative-date> by {{ updatedBy }}</div>
        </div>
    `,
    styles: [
        `
            :host {
                display: flex;
                align-items: center;
                width: 100%;
                padding: 5px 0;
            }
            abs-issue-status {
                margin-right: 10px;
            }
            .body {
                position: relative;
                flex: 1 1 auto;
            }
            .description {
                width: 100%;
                display: block;
                padding: 5px 0;
                font-style: italic;
            }
            .created,
            .updated {
                font-size: 0.85em;
            }
            .page {
                font-weight: bold;
            }
        `
    ]
})
export class IssueRowComponent implements OnChanges {
    @Input()
    issue: SupportIssue;

    @Output()
    dismiss = new EventEmitter<any>();

    updatedAt: Date;
    updatedBy: string;

    cssClass = 'body';

    @Input()
    mode: SupportMode = 'admin';

    ngOnChanges() {
        if (this.issue.updates.length > 0) {
            let latestUpdate = [...this.issue.updates].sort(
                (a, b) => new Date(b.updateDate).getTime() - new Date(a.updateDate).getTime()
            )[0];
            this.updatedAt = latestUpdate.updateDate;
            this.updatedBy = latestUpdate.updatedBy;
        } else {
            this.updatedAt = this.issue.createdAt;
            this.updatedBy = this.issue.userData.user.name;
        }
    }
}
