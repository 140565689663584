import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'arrayFieldItemName'
})
export class ArrayFieldItemNamePipe implements PipeTransform {
    transform(field: any, value: any, i: number, formValue: any): any {
        return getArrayFieldItemName(field, value, i, formValue);
    }
}

export const getArrayFieldItemName = (field: any, value: any, i: number, formValue: any) => {
    let name = `(New ${field.itemName})`;

    if (field.mapItemName) {
        name = field.mapItemName(value, formValue);
    } else if (field.nameField && value?.[field.nameField]) {
        name = value?.[field.nameField];
    } else if (value?.name?.en) {
        name = value?.name?.en;
    } else if (field.firstItemDefaultLabel && i === 0) {
        name = '(Default)';
    }

    return name;
};
