import { FORCE_ACTIONS } from '../actions';
import { ForceState } from '../state';

export const upsertForceReducer = (state: ForceState, { force }: ReturnType<typeof FORCE_ACTIONS.UPSERT_FORCE>) => {
    const forces = state.forces.map((f) => {
        if (force.id !== f.id) {
            return f;
        }

        return force;
    });

    if (!forces.find((f) => f.id === force.id)) {
        forces.push(force);
    }

    return {
        ...state,
        forces
    };
};
