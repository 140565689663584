import { NgModule } from '@angular/core';
import { SharingService } from './sharing.service';

@NgModule({
    imports: [],
    exports: [],
    declarations: [],
    providers: [SharingService]
})
export class SharingModule {}
