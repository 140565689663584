import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { NgLetModule } from 'ng-let';

import { AutoCompleteComponent } from './autocomplete.component';
import { AutoCompleteDirective } from './autocomplete.directive';

@NgModule({
    imports: [CommonModule, IonicModule, NgLetModule],
    exports: [AutoCompleteComponent, AutoCompleteDirective],
    declarations: [AutoCompleteComponent, AutoCompleteDirective],
    providers: []
})
export class AutoCompleteModule {}
