import { NgModule } from '@angular/core';
import { RelativeDatePipe } from './relative-date.pipe';
import { RelativeDateComponent } from './relative-date.component';

export * from './relative-date.component';
export * from './relative-date.pipe';

@NgModule({
    imports: [],
    exports: [RelativeDateComponent, RelativeDatePipe],
    declarations: [RelativeDateComponent, RelativeDatePipe],
    providers: []
})
export class RelativeDateModule {}
