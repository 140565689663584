import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { IonContent } from '@ionic/angular';
import { ActivatedRoute } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class ScrollService {
    outlets: {
        [key: string]: {
            scrollEl$: ReplaySubject<HTMLElement>;
            content$: ReplaySubject<IonContent>;
        };
    } = {};

    constructor(route: ActivatedRoute) {
        this.reset(route.outlet);
    }

    reset(outletKey: string) {
        this.outlets[outletKey] = {
            scrollEl$: new ReplaySubject<HTMLElement>(),
            content$: new ReplaySubject<IonContent>()
        };
    }

    getByOutlet(key: string) {
        return this.outlets[key];
    }
}
