import { Component, EventEmitter, Input, OnInit, Optional, Output, ViewChild, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Params, RouterOutlet } from '@angular/router';
import { IonContent, IonRouterOutlet, NavController, Platform } from '@ionic/angular';
import { BehaviorSubject, fromEvent, ReplaySubject, timer } from 'rxjs';
import { tap, takeUntil, map, filter, take } from 'rxjs/operators';

import { ScrollService } from '../scroll-service/scroll-service';
import { RouterService } from '../router.service';

import { ThemeService } from './theme.service';

@Component({
    selector: 'abs-page-layout',
    template: `
        <div [class]="theme">
            <ion-header>
                <ion-toolbar>
                    <ion-buttons slot="start">
                        <ion-back-button defaultHref=".." *ngIf="displayBackLink" (click)="navigateBack($event)"></ion-back-button>
                    </ion-buttons>
                    <ion-title>
                        <div class="title" (click)="titleClicked.emit()">{{ title }}</div>
                        <div class="subtitle" *ngIf="subTitle">{{ subTitle }}</div>
                    </ion-title>
                    <ion-buttons slot="end">
                        <ion-menu-button></ion-menu-button>
                    </ion-buttons>
                </ion-toolbar>
            </ion-header>
            <ion-content #content fullscreen="true">
                <div class="wrap">
                    <ng-content></ng-content>
                </div>
            </ion-content>
        </div>
    `,
    styles: [
        `
            :host {
                flex: 1 1 auto;
                display: flex;
                flex-direction: column;
            }

            :host > div {
                height: 100%;
            }

            .wrap {
                max-width: var(--wrapWidth, 600px);
                margin: 0 auto;
                padding-bottom: var(--ion-safe-area-bottom, 0);
            }
        `
    ]
})
export class PageLayoutComponent implements OnInit, AfterViewInit {
    @Input() title: string;
    @Input() subTitle: string;
    @Input() displayBackLink: boolean = true;
    @Input() preserveQueryString: boolean = false;
    @ViewChild('content') content: IonContent;

    @Output() titleClicked = new EventEmitter();
    hasScrollParent = true;

    destroy$ = new ReplaySubject();
    resize$ = fromEvent(window, 'resize').pipe(takeUntil(this.destroy$));
    resizeSub = this.resize$.subscribe((e: any) => {
        this.width$.next(e.currentTarget.innerWidth);
    });

    width$ = new BehaviorSubject(window.innerWidth);

    routeData$ = this.routerService.routeData$;

    theme: string;

    constructor(
        protected themeService: ThemeService,
        protected route: ActivatedRoute,
        protected scrollService: ScrollService,
        protected navCtrl: NavController,
        protected routerService: RouterService,
        protected platform: Platform,
        @Optional()
        protected routerOutlet: IonRouterOutlet
    ) {
        this.scrollService.reset(route.outlet);
    }

    ngOnInit(): void {
        this.routerService.routeData$.subscribe((d) => {
            this.themeService.selectTheme(d.gameId || 'home');
        });

        this.themeService.theme$
            .pipe(
                filter((t) => !!t),
                take(1)
            )
            .subscribe((t) => {
                this.theme = t;
            });
    }

    ngAfterViewInit() {
        this.initScroll();
        this.resize$.subscribe(() => {
            this.initScroll();
        });
    }

    initScroll() {
        if (this.hasScrollParent) {
            const cancelLoop$ = new ReplaySubject();
            timer(0, 200)
                .pipe(
                    tap((i) => {
                        if (i > 10) {
                            cancelLoop$.next(true);
                        }
                    }),
                    takeUntil(cancelLoop$),
                    takeUntil(this.scrollService.outlets[this.route.outlet].scrollEl$),
                    map(async () => await this.content),
                    filter((x) => !!x)
                )
                .subscribe(async () => {
                    if (!this.content) {
                        return;
                    }
                    this.content.scrollEvents = true;
                    this.scrollService.outlets[this.route.outlet].content$.next(await this.content);
                    this.scrollService.outlets[this.route.outlet].scrollEl$.next(await this.content.getScrollElement());
                });
        }
    }

    navigateBack(ev) {
        let qs: Params = {};
        if (this.preserveQueryString) {
            qs = this.route.snapshot.queryParams;
        }
        // if (!this.routerOutlet) {
        const startingPath = location.pathname;
        const urlParts = startingPath.split('/');
        this.navCtrl.setDirection('back');

        const tryParentRoute = () => {
            if (urlParts.length > 1) {
                urlParts.pop();
                const url = urlParts.join('/');

                console.log('Attempting to navigate to ' + url);
                return this.navCtrl
                    .navigateBack(url, { animationDirection: 'back', queryParams: qs })
                    .then(() => {
                        if (location.pathname === startingPath) {
                            return tryParentRoute();
                        } else {
                            ev.preventDefault();
                        }
                    })
                    .catch(() => {
                        return tryParentRoute();
                    });
            } else {
                console.log('No ancestor route found.  Navigating to root.');
                return this.navCtrl.navigateBack('/', { animationDirection: 'back' });
            }
        };

        return tryParentRoute();
        // }
    }
}
