import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';

import { PageLayoutComponent } from './page-layout.component';

@NgModule({
    declarations: [PageLayoutComponent],
    imports: [CommonModule, IonicModule],
    exports: [PageLayoutComponent]
})
export class PageLayoutModule {}
