import { Component, Input } from '@angular/core';
import { Platform, PopoverController } from '@ionic/angular';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { UntypedFormBuilder } from '@angular/forms';

import { DevicePlatform, SocialSigninService } from './service/social-signin.service';
import { WarlordUserService } from './user.service';

@Component({
    selector: 'wl-login',
    template: `
        <div
            *ngLet="{
                loginState: userService.login$ | async
            } as props"
        >
            <div class="error" *ngIf="props.loginState.error">
                {{ 'GLOBAL.ERRORS.' + props.loginState.error | translate }}
            </div>

            <div class="loggedIn" *ngIf="props.loginState.user; else loggedOut">
                <div class="buttons" *ngIf="showLogout">
                    <ion-button class="logout" (click)="logout()">
                        {{ 'GLOBAL.LOGIN.BUTTONS.LOGOUT' | translate }}
                        <ion-spinner name="crescent" *ngIf="props.loginState.loading"></ion-spinner>
                    </ion-button>
                </div>
            </div>
            <ng-template #loggedOut>
                <div class="loginButtons">
                    <ion-button type="submit" *ngIf="platform.is('ios')" class="apple" (click)="appleLogin()" expand="block">
                        <ion-icon name="logo-apple"></ion-icon>
                        Login with Apple <ion-spinner name="crescent" *ngIf="props.loginState.loading"></ion-spinner>
                    </ion-button>
                    <ion-button type="submit" class="google" color="danger" (click)="googleLogin()" expand="block">
                        <ion-icon name="logo-google"></ion-icon>
                        Login with Google <ion-spinner name="crescent" *ngIf="props.loginState.loading"></ion-spinner>
                    </ion-button>
                    <ion-button type="submit" *ngIf="!platform.is('ios')" class="apple" (click)="appleLogin()" expand="block">
                        <ion-icon name="logo-apple"></ion-icon>
                        Login with Apple <ion-spinner name="crescent" *ngIf="props.loginState.loading"></ion-spinner>
                    </ion-button>
                    <!-- <ion-button type="submit" class="facebook" (click)="facebookLogin()" expand="block">
                        <ion-icon name="logo-facebook"></ion-icon>
                        Login with Facebook <ion-spinner name="crescent" *ngIf="props.loginState.loading"></ion-spinner>
                    </ion-button> -->
                </div>
            </ng-template>
        </div>
    `,
    styles: [
        `
            .error {
                margin: 10px 0;
            }

            .buttons {
                padding: 0;
            }

            .loginButtons ion-icon {
                margin-right: 10px;
            }

            .logout {
                border: 0;
                color: #e0e0e0;
                --background: #a22 !important;
            }

            .logout:hover {
                color: #e0e0e0;
                /* --background: #911 !important;*/
            }

            .loggedOut {
                padding: 0 2px 5px;
            }

            ion-input {
                border: none;
            }

            .google {
                --ion-color-primary: #de5246 !important;
                --background: var(--ion-color-primary) !important;
                border-color: var(--ion-color-primary) !important;
                color: #fff !important;
            }

            .apple {
                --ion-color-primary: #000 !important;
                --background: var(--ion-color-primary) !important;
                border-color: var(--ion-color-primary) !important;
                color: #fff !important;
            }

            .facebook {
                --ion-color-primary: #4267b2;
                color: #fff;
            }
        `
    ]
})
export class LoginComponent {
    @Input()
    infoPopup: boolean;

    @Input()
    showInfo: boolean = true;

    @Input()
    showLogout: boolean = true;

    @Input()
    showLogin: boolean = true;

    @Input()
    showRegister: boolean = true;

    @Input()
    showGuest: boolean = false;

    formGroup = this.fb.group({
        username: this.fb.control(''),
        password: this.fb.control('')
    });

    constructor(
        public userService: WarlordUserService,
        private iab: InAppBrowser,
        public popoverController: PopoverController,
        private fb: UntypedFormBuilder,
        private socialSigninService: SocialSigninService,
        public platform: Platform
    ) {}

    async showInfoPopup(ev: any) {
        const popover = await this.popoverController.create({
            component: LoginInfoComponent,
            event: ev,
            translucent: true
        });
        return await popover.present();
    }

    logout() {
        this.userService.logout();
    }

    async googleLogin() {
        const platform: DevicePlatform = this.getPlatform();
        await this.socialSigninService.googleLogin(platform);
    }

    private getPlatform() {
        let platform: DevicePlatform;
        if (this.platform.is('android')) {
            platform = 'android';
        } else if (this.platform.is('ios')) {
            platform = 'ios';
        } else {
            platform = 'web';
        }
        return platform;
    }

    async appleLogin() {
        const platform: DevicePlatform = this.getPlatform();
        await this.socialSigninService.appleLogin(platform);
    }

    async facebookLogin() {
        const platform: DevicePlatform = this.getPlatform();
        await this.socialSigninService.facebookLogin(platform);
    }
}

@Component({
    selector: 'wl-login-info',
    template: `<p>{{ 'GLOBAL.LOGIN.DESCRIPTION' | translate }}</p>`
})
export class LoginInfoComponent {}
