import { Component, OnInit } from '@angular/core';
import { snapshot, ArmyBuilderConfig } from '../../global';

@Component({
    selector: 'abs-translation-list',
    template: `
        <abs-page-layout title="Translations">
            <div class="content">
                <ion-list>
                    <ion-item *ngFor="let lang of languages" [routerLink]="'./' + lang">{{ lang }}</ion-item>
                </ion-list>
            </div>
        </abs-page-layout>
    `
})
export class TranslationListComponent {
    languages = this.config.supportedLanguages;
    constructor(private config: ArmyBuilderConfig) {}
}
