import { NgModule } from '@angular/core';

import { ModalLayoutComponent } from './modal-layout.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    imports: [CommonModule, IonicModule, TranslateModule],
    exports: [ModalLayoutComponent],
    declarations: [ModalLayoutComponent],
    providers: []
})
export class ModalLayoutModule {}
