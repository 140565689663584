import { NgModule } from '@angular/core';
import { QRCodeService } from './qr-code.service';
import { BarcodeScanner } from '@awesome-cordova-plugins/barcode-scanner/ngx';

@NgModule({
    imports: [],
    exports: [],
    declarations: [],
    providers: [QRCodeService, BarcodeScanner]
})
export class QRCodeModule {}
