import { Injectable } from '@angular/core';
import { Storage as IonicStorage } from '@ionic/storage';

@Injectable({ providedIn: 'root' })
export class NativeStorageService {
    private _storage: IonicStorage | null = null;

    constructor(private storage: IonicStorage) {
        this.init();
    }

    async init() {
        // If using, define drivers here: await this.storage.defineDriver(/*...*/);
        const storage = await this.storage.create();
        this._storage = storage;
    }

    async getItem(key: string, defaultValue: any): Promise<any> {
        await this.checkServiceInit();

        const val = await this._storage.keys();
        if (!val.includes(key)) {
            return defaultValue;
        }

        const value = await this._storage.get(key);
        try {
            return JSON.parse(value);
        } catch (err) {
            return value;
        }
    }

    setItem(key: string, value: any) {
        return this._storage.set(key, value);
    }

    async clear() {
        // Preserve deviceId
        const device = await this.getItem('device', '');
        const appVersion = await this.getItem('appVersion', '');
        const translations = {};

        const keys = await this._storage.keys();
        const translateKeys = keys.filter((x) => x.includes('translate'));
        if (translateKeys.length > 0) {
            await Promise.all(
                keys.map(async (key) => {
                    translations[key] = await this.getItem(key, '');
                })
            );
        }

        await this._storage.clear();
        if (device) {
            this.setItem('device', device);
        }
        if (appVersion) {
            this.setItem('appVersion', appVersion);
        }

        Object.keys(translations).forEach((key) => {
            this.setItem(key, translations[key]);
        });
    }

    async checkServiceInit() {
        return new Promise((resolve, reject) => {
            const startTime = Date.now();

            const checkInterval = setInterval(() => {
                if (this._storage) {
                    clearInterval(checkInterval);
                    resolve(this._storage);
                } else if (Date.now() - startTime >= 5000) {
                    clearInterval(checkInterval);
                    reject(new Error('Storage is not truthy after 5 seconds'));
                }
            }, 100); // Check every 100 milliseconds
        });
    }
}
