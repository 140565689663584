import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import { SettingsService } from '../../global/settings';
import { ForceUtils } from '../force.utils';
import { Force, Unit } from '../models';

@Pipe({
    name: 'unitPageSubtitle'
})
export class UnitPageSubtitlePipe implements PipeTransform {
    constructor(
        private forceUtils: ForceUtils,
        private translate: TranslateService,
        private settingsService: SettingsService
    ) {}

    transform(unit: Unit, force: Force): Observable<string> {
        const pts$ = this.translate.get('GLOBAL.PTS');

        return combineLatest([pts$, this.forceUtils.getUnitCost(unit, force), this.settingsService.settings$]).pipe(
            map(([pts, cost, settings]) => {
                let subtitle = `${cost}${pts}`;
                if (settings[force.gameId]?.show_force_cost_on_unit_page) {
                    subtitle += ` / ${force.cost}${pts}`;
                }
                return subtitle;
            })
        );
    }
}
