import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { ParseIconsPipe } from './parse-icons.pipe';

@NgModule({
    imports: [CommonModule, TranslateModule],
    exports: [ParseIconsPipe],
    declarations: [ParseIconsPipe],
    providers: []
})
export class ParseIconsModule {}
