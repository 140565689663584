<div *ngIf="parentFormGroup" [formGroup]="parentFormGroup" class="wrapper" [class.inline]="field.inline">
    <h3 *ngIf="field.header">{{ field.header }}</h3>
    <ion-reorder-group *ngIf="fields" [formArrayName]="field.name" [disabled]="false" (ionItemReorder)="doReorder($any($event))">
        <div *ngFor="let control of fields.controls; let i = index">
            <ion-item [color]="getItemHeaderColor(control)" (click)="showItem($event, control.value)" [class.first]="i === 0">
                {{ field | arrayFieldItemName : control.value : i : formValue }}
                <ion-reorder slot="start"></ion-reorder>
            </ion-item>
            <div class="arrayFieldItem" [class.hidden]="control.value.id !== selectedItemId" [formGroup]="$any(control)" (click)="close()">
                <ion-card (click)="$event.stopPropagation()" *ngIf="!field.inline">
                    <ion-item lines="full">
                        <ion-icon name="create" slot="start"></ion-icon>
                        <ion-title>Edit {{ field.itemName }}</ion-title>
                        <ion-icon name="close" slot="end" (click)="close()"></ion-icon>
                    </ion-item>
                    <ion-card-content>
                        <abs-universal-field
                            *ngFor="let itemField of field.fields"
                            [field]="itemField"
                            [formValue]="formValue"
                        ></abs-universal-field>
                    </ion-card-content>
                    <ion-footer>
                        <ion-item lines="none">
                            <ion-button color="danger" slot="start" (click)="deleteItem(i)">Delete {{ field.itemName }}</ion-button>
                            <ion-button color="warning" slot="start" (click)="addItem(control.value)"
                                >Duplicate {{ field.itemName }}</ion-button
                            >
                            <ion-button fill="outline" slot="end" (click)="close()">Close</ion-button>
                        </ion-item>
                    </ion-footer>
                </ion-card>
                <div (click)="$event.stopPropagation()" *ngIf="field.inline">
                    <abs-universal-field
                        *ngFor="let itemField of field.fields"
                        [field]="itemField"
                        [formValue]="formValue"
                    ></abs-universal-field>
                    <ion-item lines="none">
                        <ion-button color="danger" slot="start" (click)="deleteItem(i)">Delete {{ field.itemName }}</ion-button>
                        <ion-button color="warning" slot="start" (click)="addItem(control.value)"
                            >Duplicate {{ field.itemName }}</ion-button
                        >
                        <ion-button fill="outline" slot="end" (click)="close()">Close</ion-button>
                    </ion-item>
                </div>
            </div>
        </div>
    </ion-reorder-group>
    <ion-item>
        <ion-button (click)="addItem()">Add {{ field.itemName }}</ion-button>
    </ion-item>
</div>
