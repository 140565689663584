import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { createForm, FormConfig, patchForm } from '../../config/base-form.config';
import { formDiff } from '../../../global/utils';
import { FormField } from '../form-field';

@Component({
    selector: 'abs-form',
    template: `
        <form *ngIf="config && formGroup" (ngSubmit)="onSubmit(formGroup.value)" [formGroup]="formGroup">
            <abs-universal-field
                *ngFor="let field of config.formDefinition"
                [field]="field"
                [formValue]="formGroup.value"
            ></abs-universal-field>
            <ng-content></ng-content>
        </form>
    `
})
export class FormComponent implements OnInit, OnChanges {
    @Input()
    config: FormConfig;

    @Input()
    value: any;

    @Output()
    formSubmit = new EventEmitter<any>();

    formGroup: UntypedFormGroup;

    ngOnInit() {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes?.config?.currentValue) {
            this.formGroup = createForm(this.config.formDefinition);
            if (this.value) {
                this.loadData();
            }
        }

        if (changes?.value?.currentValue && this.formGroup) {
            this.loadData();
        }
    }

    loadData() {
        patchForm(this.formGroup, this.config.formDefinition, this.value);
    }

    onSubmit(value) {
        let formValue = this.fixDataTypes(value, this.config.formDefinition);
        let oldValue = JSON.stringify(value);
        let newValue = JSON.stringify(formValue);
        if (oldValue !== newValue) {
            console.error({ oldValue, newValue });
        }
        this.formSubmit.emit(formValue);
    }

    fixDataTypes(_formValue, fields: FormField[]) {
        let formValue = { ..._formValue };
        for (let f of fields) {
            if (f.type === 'number') {
                const prop = formValue[f.name];
                if (f.multi) {
                    for (let i = 0; i < prop.length; i++) {
                        const fieldValue = typeof prop[i] === 'string' ? parseInt(prop[i]) : prop[i];
                        prop[i] = fieldValue;
                    }
                    formValue[f.name] = prop;
                } else {
                    const fieldValue = typeof prop === 'string' ? parseInt(prop) : prop;
                    formValue[f.name] = fieldValue;
                }
            } else if (f.type === 'array') {
                const prop = formValue[f.name] || [];
                for (let i = 0; i < prop.length; i++) {
                    prop[i] = this.fixDataTypes(prop[i], f.fields);
                }
            } else if (f.type === 'group') {
                const prop = formValue[f.name];
                formValue[f.name] = this.fixDataTypes(prop, f.fields);
            } else if (f.type === 'section') {
                formValue = this.fixDataTypes(formValue, f.fields);
            }
        }
        return formValue;
    }
}
