import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { dateString } from '../utils';

import { AppendLogMessage, selectLog } from './state';

@Injectable({ providedIn: 'root' })
export class DebugService {
    public log$: Observable<string[]> = this.store.select(selectLog);

    constructor(private store: Store) {}

    getInstance(prefix: string): Logger {
        return {
            log: (...args: any[]) => {
                const message = `${prefix || ''} ${dateString(new Date())} ${args
                    .map((x) => {
                        if (typeof x === 'string') {
                            return x;
                        }

                        if (typeof x === 'object') {
                            return JSON.stringify(x, null, 4);
                        }

                        return x?.toString();
                    })
                    .join(', ')}`;
                // console.log(message);
                this.store.dispatch(AppendLogMessage({ message }));
            }
        };
    }
}

export interface Logger {
    log: (...args: any[]) => void;
}
