import { EntityMetadataMap } from '@ngrx/data';

const entityMetadata: EntityMetadataMap = {
    Force: {},
    Battle: {}
};

export const entityConfig = {
    entityMetadata
};
