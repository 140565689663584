import { Routes } from '@angular/router';
import { AdminGuard } from '../admin.guard';
import { TranslationFormComponent } from './translation-form.component';
import { TranslationListComponent } from './translation-list.component';

export const TranslationsRoutes: Routes = [
    {
        path: '',
        component: TranslationListComponent,
        canActivate: [AdminGuard]
    },
    {
        path: ':lang',
        component: TranslationFormComponent,
        canActivate: [AdminGuard]
    }
];
