import { createAction, props } from '@ngrx/store';
import { Force } from './models';

export const sharedForcesLoaded = createAction(
    '[SharedForces] sharedForcesLoaded',
    props<{ gameId: string; forces: Force[] }>()
);

export const removeSharedForce = createAction(
    '[SharedForces] removeSharedForce',
    props<{ gameId: string; forceId: string }>()
);
