import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ModalComponent } from './modal.component';
import { IonicModule } from '@ionic/angular';
import { Modal } from './modal';

@NgModule({
    imports: [CommonModule, IonicModule],
    declarations: [ModalComponent],
    exports: [ModalComponent],
    providers: [Modal]
})
export class ModalModule {}
