import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { BaseFormControl } from '../base-control';
import { FormTagField } from '../form-field';

@Component({
    selector: 'abs-tag',
    template: `
        <div class="form-group" *ngIf="parentFormGroup" [formGroup]="parentFormGroup">
            <label *ngIf="!field.hideLabel">{{ field.label || (field.name | formLabel) }} </label>
            <div class="tags">
                <input type="hidden" [formControlName]="field.name" />
                <span *ngIf="formControl.value" (auxclick)="$event.which === 2 ? clearItem($event) : undefined">
                    {{
                        field.mapItemName ? field.mapItemName(formControl.value, formValue) : (formControl.value | tagLabel : field.options)
                    }}
                    <span class="removeTag" (click)="clearItem($event)">x</span>
                </span>

                <input
                    *ngIf="!formControl.value"
                    type="text"
                    [value]="value"
                    (input)="updateValue($event)"
                    (keydown.Enter)="onEnter($event)"
                    (keyDown)="onEnter($event)"
                    absAutoComplete
                    [options]="field.options"
                    [sortOptionsFn]="field.sortOptionsFn ? field.sortOptionsFn(formValue) : null"
                    (selectionUpdate)="selectionUpdate($event)"
                    (commitSelection)="commitSelection($event)"
                />
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TagComponent extends BaseFormControl {
    @Input()
    field: FormTagField;

    value: string = '';

    options$ = new BehaviorSubject([]);

    get formControl() {
        return this.parentFormGroup.get(this.field.name) as UntypedFormControl;
    }

    onEnter(e: any) {
        let val = e.srcElement.value.trim();
        if (!val) {
            return;
        }
        this.save(val);
        e.target.focus();
    }

    clearItem(e: any) {
        e.preventDefault();
        e.stopPropagation();
        this.formControl.reset(null);
    }

    updateValue(e) {
        this.value = e.target.value || '';
    }

    selectionUpdate(e) {
        this.value = e || '';
    }

    save(val: string) {
        this.formControl.patchValue(val);
        this.value = '';
    }

    commitSelection(val: string) {
        this.save(val);
    }
}
