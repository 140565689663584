import { Component, Input, OnChanges, OnInit, Pipe, PipeTransform } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PaginatedItems } from '../../../../global';
import { map } from 'rxjs/operators';

@Component({
    selector: 'abs-pager',
    template: `
        <p>Showing {{ items.skip + 1 }} - {{ getPageEnd() }} of {{ items.itemCount }} items</p>
        <ul>
            <li *ngFor="let i of startPages" [class.selected]="items.skip === i * items.pageSize">
                <a [routerLink]="['.']" [relativeTo]="this.route" [queryParams]="i | pagerQueryParams : items | async">{{ i + 1 }}</a>
            </li>
            <li *ngIf="startPages?.length > 0">...</li>
            <li *ngFor="let i of midPages" [class.selected]="items.skip === i * items.pageSize">
                <a [routerLink]="['.']" [relativeTo]="this.route" [queryParams]="i | pagerQueryParams : items | async">{{ i + 1 }}</a>
            </li>

            <li *ngIf="endPages?.length > 0">...</li>
            <li *ngFor="let i of endPages" [class.selected]="items.skip === i * items.pageSize">
                <a [routerLink]="['.']" [relativeTo]="this.route" [queryParams]="i | pagerQueryParams : items | async">{{ i + 1 }}</a>
            </li>
        </ul>
    `,
    styles: [
        `
            p {
                text-align: center;
            }

            ul {
                text-align: center;
                padding: 0;
                margin: 0;
            }

            ul > li {
                display: inline-block;
                margin: 0 2px;
            }

            ul > li > a {
                padding: 4px;
            }

            .selected {
                font-weight: bold;
            }
        `
    ]
})
export class PagerComponent implements OnChanges {
    @Input()
    items: PaginatedItems;

    startPages: number[];
    midPages: number[];
    endPages: number[];

    constructor(public route: ActivatedRoute) {}

    ngOnChanges() {
        this.calculatePager();
    }

    calculatePager() {
        setTimeout(() => {
            let count = this.items.pageCount;
            let pages = [...Array(count).keys()].map((_, i) => i);
            if (count <= 10) {
                this.startPages = [];
                this.midPages = pages;
                this.endPages = [];
            } else {
                let i = this.items.pageIndex;
                let start = i - 2;
                let end = i + 3;

                if (start < 0) {
                    start = 0;
                }

                if (end > pages.length - 1) {
                    end = pages.length - 1;
                }

                this.midPages = pages.slice(start, end);

                if (start > 3) {
                    this.startPages = [0, 1, 2];
                } else {
                    this.startPages = [];
                    this.midPages = pages.slice(0, end);
                }

                if (end < pages.length - 3) {
                    this.endPages = pages.slice(-3);
                } else {
                    this.endPages = [];
                    this.midPages = pages.slice(start);
                }
            }
        });
    }

    getPageEnd() {
        return Math.min(this.items.skip + this.items.pageSize, this.items.itemCount);
    }
}

@Pipe({
    name: 'pagerQueryParams'
})
export class PagerQueryParamsPipe implements PipeTransform {
    constructor(private route: ActivatedRoute) {}

    transform(i: any, items: PaginatedItems): any {
        return this.route.queryParams.pipe(map((queryParams) => ({ ...queryParams, skip: i * items.pageSize })));
    }
}
