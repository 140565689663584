import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { Translation } from '../i18n/translation.model';
import { getValueFromTranslation } from './selectLanguage';

@Pipe({
    name: 'language'
})
export class LanguagePipe implements PipeTransform {
    transform(value: Translation): Observable<string> {
        return getValueFromTranslation(value);
    }
}
