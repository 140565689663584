import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { BaseFormControl } from '../base-control';
import { FormCheckboxField } from '../form-field';

@Component({
    selector: 'abs-checkbox',
    template: `
        <div class="form-group checkbox" *ngIf="parentFormGroup" [formGroup]="parentFormGroup">
            <ion-checkbox [formControlName]="field.name"></ion-checkbox>
            <label position="floating">{{ field.label || (field.name | formLabel) }} </label>
            <ion-icon class="info" *ngIf="field.description" name="information-circle" (click)="showDescription($event)"></ion-icon>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckboxComponent extends BaseFormControl {
    @Input()
    field: FormCheckboxField;
}
