import { NgModule, ErrorHandler } from '@angular/core';
import { DebugComponent } from './debug.component';
import { CommonModule } from '@angular/common';
import { DebugService } from './debug.service';
import { IonicModule } from '@ionic/angular';
import { DebugRoutingModule } from './debug.routing';
import { debugStateReducer } from './state';
import { Clipboard } from '@awesome-cordova-plugins/clipboard/ngx';
import { ErrorLogger } from './error-logger';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { PageLayoutModule } from '../page-layout';

@NgModule({
    declarations: [DebugComponent],
    exports: [DebugComponent],
    imports: [
        CommonModule,
        IonicModule,
        DebugRoutingModule,
        StoreModule.forFeature('debug', debugStateReducer),
        TranslateModule,
        PageLayoutModule
    ],
    providers: [DebugService, Clipboard, { provide: ErrorHandler, useClass: ErrorLogger }]
})
export class DebugModule {}
