import { AlertController } from '@ionic/angular';
import { Injectable } from '@angular/core';

import { parseIcons } from '../parseIcons';

@Injectable({
    providedIn: 'root'
})
export class AlertService {
    constructor(private alertController: AlertController) {}

    private async show(header = '', message: string, buttons?: any) {
        const alert = await this.alertController.create({
            header,
            message: parseIcons(message),
            buttons: buttons || [
                {
                    text: 'Ok',
                    role: 'cancel',
                    cssClass: 'alertButton'
                }
            ]
        });
        await alert.present();
        return alert.onDidDismiss();
    }

    async showAlert(header: string, message: string, buttons?: any) {
        this.show(header, message, buttons);
    }

    async showConfirm(header: string = '', message: string, buttonText: { ok: string; cancel: string } = { ok: 'OK', cancel: 'Cancel' }) {
        return this.show(header, message, [
            {
                text: buttonText.cancel,
                role: 'cancel'
            },
            {
                text: buttonText.ok,
                role: 'ok'
            }
        ]);
    }
}
