import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { ArmyBuilderConfig } from '../../../global';

@Component({
    selector: 'abs-translation-modal',
    template: `
        <abs-modal-layout [title]="'Override Tag Text'" [closeBtnTextKey]="'Close'" (close)="closeModal()">
            <form *ngIf="parentFormGroup" [formGroup]="parentFormGroup">
                <div class="keyExplanation" *ngIf="value.key">
                    <p>
                        If no translation is provided for the selected language, then the default translation for "<strong>{{
                            value.key
                        }}</strong
                        >" will be displayed. If you need to display a different value, you may enter the language specific value here. For
                        example, the Japanese Officer has the following text: "If the officer model is depicted with a sword he has the
                        tough fighter special rule". Providing a translation here makes it clear that the special rule is conditional,
                        rather than simply using the default translation of "Tough Fighter", which implies that the Officer always has that
                        rule.
                    </p>
                    <input type="hidden" [formControlName]="'key'" />
                </div>
                <div class="form-control" *ngFor="let lang of supportedLanguages">
                    <label>{{ lang }}</label>
                    <ion-input [formControlName]="lang"></ion-input>
                </div>
            </form>
        </abs-modal-layout>
    `,
    styles: [
        `
            .keyExplanation {
                border-bottom: 1px solid #e0e0e0;
                margin-bottom: 10px;
                padding-bottom: 10px;
            }
        `
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TranslationModalComponent {
    @Input()
    value: any;

    @Input()
    parentFormGroup: UntypedFormGroup;

    @Output()
    onFormGroupChange = new EventEmitter<any>();

    supportedLanguages = this.config.supportedLanguages;

    constructor(private modalController: ModalController, private config: ArmyBuilderConfig) {}

    closeModal() {
        if (this.parentFormGroup.valid) {
            this.modalController.dismiss(this.parentFormGroup.value);
        }
    }
}
