import { Force } from '../models';

export interface ForceState {
    forces: Force[];
    library: {
        [key: string]: any[];
    };
}

export const initialForceState: ForceState = {
    forces: [],
    library: {}
};
