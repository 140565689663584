import { createAnimation } from '@ionic/angular';
import { NavOptions } from '@ionic/core';

export class SidebarNavOpts {
    // Slightly hacky way to ensure the back button triggers a back animation in the sidebar,
    // as the sidebar, unlike the main router outlet thinks the direction is forward.  The
    // direction is set in the PageLayoutComponent on clicking the back button then reset
    // 100ms later
    static direction: 'forward' | 'back' = 'forward';
}

export interface TransitionOptions extends NavOptions {
    progressCallback?: (ani: Animation | undefined) => void;
    baseEl: any;
    enteringEl: HTMLElement;
    leavingEl: HTMLElement | undefined;
}

export const getIonPageElement = (element: HTMLElement) => {
    if (element.classList.contains('ion-grid')) {
        return element;
    }

    const ionPage = element.querySelector('ion-grid, :scope > ion-nav, :scope > ion-tabs');
    if (ionPage) {
        return ionPage;
    }
    // idk, return the original element so at least something animates
    // and we don't have a null pointer
    return element;
};

export function pageTransition(el: HTMLElement, opts: TransitionOptions) {
    const DURATION = 300;

    // root animation with common setup for the whole transition
    const rootTransition = createAnimation()
        // .delay(200)
        .duration(opts.duration || DURATION)
        .easing('cubic-bezier(0.3,0,0.66,1)');

    // ensure that the entering page is visible from the start of the transition
    const enteringPage = createAnimation().addElement(getIonPageElement(opts.enteringEl)).beforeRemoveClass('ion-page-invisible');

    // create animation for the leaving page
    const leavingPage = createAnimation().addElement(getIonPageElement(opts.leavingEl));
    if (opts.leavingEl.nodeName === opts.enteringEl.nodeName) {
        return rootTransition;
    }

    // actual customized animation
    if (opts.direction === 'back' || (el.id === 'sidebar' && SidebarNavOpts.direction === 'back')) {
        leavingPage.fromTo('transform', 'translate3d(0,0,0)', 'translate3d(100%,0,0)');
        enteringPage.fromTo('transform', 'translate3d(-100%,0,0)', 'translate3d(0,0,0)').fromTo('opacity', '0', '1');
    } else {
        enteringPage.fromTo('transform', 'translate3d(100%,0,0)', 'translate3d(0,0,0)').fromTo('opacity', '0', '1');
        leavingPage.fromTo('transform', 'translate3d(0,0,0)', 'translate3d(-100%,0,0)');
    }

    // include animations for both pages into the root animation
    rootTransition.addAnimation(enteringPage);
    rootTransition.addAnimation(leavingPage);
    return rootTransition;
}
