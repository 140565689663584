import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'abs-relative-date',
    template: ` <span [title]="date">{{ date | relativeDate }}</span> `
})
export class RelativeDateComponent {
    @Input()
    date: Date;
}
