import { Component, Input, OnInit } from '@angular/core';
import { IssueStatus } from './model';

@Component({
    selector: 'abs-issue-status',
    template: `
        <span title="{{ status }}">
            <ion-icon [color]="'warning'" *ngIf="status === 'New'" name="alert-circle"></ion-icon>
            <ion-icon [color]="'warning'" *ngIf="status === 'Customer responded'" name="close-circle"></ion-icon>
            <ion-icon *ngIf="status === 'In progress'" name="ellipse"></ion-icon>
            <ion-icon *ngIf="status === 'Waiting for customer'" name="ellipse"></ion-icon>
            <ion-icon [color]="'success'" *ngIf="status === 'Resolved'" name="checkmark-circle"></ion-icon>
            <ion-icon [color]="'danger'" *ngIf="status === 'Rejected'" name="close-circle"></ion-icon>
        </span>
    `
})
export class IssueStatusComponent {
    @Input()
    status: IssueStatus;
}
