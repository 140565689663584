import { ForceState } from '../state';
import { FORCE_ACTIONS } from '../actions';

export const addPointsAdjustmentReducer = (
    state: ForceState,
    { forceId, unitId, pointsAdjustment }: ReturnType<typeof FORCE_ACTIONS.ADD_POINTS_ADJUSTMENT>
) => {
    const forces = state.forces.map((f) => {
        if (forceId !== f.id) {
            return f;
        }

        if (unitId === undefined) {
            return { ...f, pointsAdjustments: [...(f.pointsAdjustments || []), pointsAdjustment] };
        } else {
            return {
                ...f,
                units: f.units.map((u) => {
                    if (unitId !== u.id) {
                        return u;
                    }

                    return {
                        ...u,
                        pointsAdjustments: [...(u.pointsAdjustments || []), pointsAdjustment]
                    };
                })
            };
        }
    });

    return {
        ...state,
        forces
    };
};
