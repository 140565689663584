import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DebugComponent } from './debug.component';

export const DEBUG_ROUTES: Routes = [{ path: '', component: DebugComponent, pathMatch: 'full', data: { hideSidebar: true } }];

@NgModule({
    imports: [RouterModule.forChild(DEBUG_ROUTES)],
    exports: [RouterModule]
})
export class DebugRoutingModule {}
