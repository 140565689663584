import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LanguagePipe } from './language.pipe';

@NgModule({
    imports: [CommonModule],
    declarations: [LanguagePipe],
    exports: [LanguagePipe]
})
export class LanguageModule {}
