import { FORCE_ACTIONS } from '../actions';
import { ForceState } from '../state';

export const removePointsAdjustmentReducer = (
    state: ForceState,
    { forceId, unitId, index }: ReturnType<typeof FORCE_ACTIONS.REMOVE_POINTS_ADJUSTMENT>
) => {
    const forces = state.forces.map((f) => {
        if (forceId !== f.id) {
            return f;
        }

        if (unitId === undefined) {
            const adjustedForce = { ...f, pointsAdjustments: [...f.pointsAdjustments] };
            adjustedForce.pointsAdjustments.splice(index, 1);
            return adjustedForce;
        } else {
            return {
                ...f,
                units: f.units.map((u) => {
                    if (unitId !== u.id) {
                        return u;
                    }

                    const adjustedUnit = { ...u, pointsAdjustments: [...u.pointsAdjustments] };
                    adjustedUnit.pointsAdjustments.splice(index, 1);
                    return adjustedUnit;
                })
            };
        }
    });

    return {
        ...state,
        forces
    };
};
