import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy, Router } from '@angular/router';
import { IonicModule, IonicRouteStrategy, Platform, ToastController } from '@ionic/angular';
import {
    ArmyBuilderConfig,
    ArmyBuilderSharedModule,
    ArmyBuilderStoreModule,
    SettingsModule,
    UserService,
    LanguageModule,
    SupportModule,
    pageTransition,
    PurchaseModule,
    DEFAULT_SETTINGS_VALUE,
    SupportService,
    SettingsService
} from 'army-builder-shared';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { NgLetModule } from 'ng-let';
import { KeepAwake } from '@capacitor-community/keep-awake';
import { provideAuth } from '@angular/fire/auth';
import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { getApp } from 'firebase/app';
import { initializeAuth, indexedDBLocalPersistence, getAuth } from 'firebase/auth';
import { Capacitor } from '@capacitor/core';
import { IonicStorageModule } from '@ionic/storage-angular';
import { debounceTime, filter, map, withLatestFrom } from 'rxjs';

import { environment } from '../environments/environment';
import { RelativeDateModule } from '../../../army-builder-shared/src/lib/global/relative-date/index';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { WarlordConfig } from './config';
import { HomePage } from './home/home.page';
import { GameListPage } from './home/game-list.page';
import { WarlordSettingsModule } from './settings/settings.module';
import { MenuModule } from './global/menu/menu.module';
import { WarlordUserService } from './settings/user/user.service';
import { ContentPage } from './home/content.page';
import { SubscriptionModule } from './subscription/subscription.module';
import { PageLayoutModule } from './global/page-layout';

@NgModule({
    declarations: [AppComponent, HomePage, GameListPage, ContentPage],
    bootstrap: [AppComponent],
    imports: [
        BrowserModule,
        IonicModule.forRoot({
            mode: 'md',
            navAnimation: pageTransition
        }),
        AppRoutingModule,
        MenuModule,
        ArmyBuilderStoreModule,
        ArmyBuilderSharedModule,
        TranslateModule,
        SettingsModule,
        WarlordSettingsModule,
        NgLetModule,
        PageLayoutModule,
        LanguageModule,
        SubscriptionModule,
        NgLetModule,
        SupportModule,
        IonicStorageModule.forRoot(),
        PurchaseModule.forRoot(),
        RelativeDateModule
    ],
    providers: [
        provideFirebaseApp(() => initializeApp(environment.firebase)),
        provideAuth(() => {
            if (Capacitor.isNativePlatform()) {
                return initializeAuth(getApp(), {
                    persistence: indexedDBLocalPersistence
                });
            } else {
                return getAuth();
            }
        }),
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: ArmyBuilderConfig, useClass: WarlordConfig },
        WarlordUserService,
        {
            provide: UserService,
            useExisting: WarlordUserService
        },
        {
            provide: DEFAULT_SETTINGS_VALUE,
            useValue: {
                // itemFilters: { item: { type: EQUIPMENT_TYPES } },
                // itemSort: {
                //     item: {}
                // },
                // disableGrouping: {
                //     item: {}
                // },
                // showIllegalItems: {
                //     item: {}
                // }
            }
        },
        provideHttpClient(withInterceptorsFromDi())
    ]
})
export class AppModule {
    constructor(
        public platform: Platform,
        private supportService: SupportService,
        private settingsService: SettingsService,
        private toastController: ToastController,
        private router: Router
    ) {
        this.platform.ready().then(() => {
            KeepAwake.keepAwake().then(
                () => console.log('keepAwake success'),
                (e) => console.log('keepAwake error', e)
            );

            this.settingsService.loggedIn$
                .pipe(
                    filter((loggedIn) => loggedIn),
                    debounceTime(500),
                    withLatestFrom(this.supportService.unreadCount$)
                )
                .subscribe(async ([_, unreadCount]) => {
                    if (unreadCount > 0) {
                        const toast = await this.toastController.create({
                            message: `You have ${unreadCount} support tickets with new updates.`,
                            position: 'top',
                            color: 'warning',
                            layout: 'stacked',
                            buttons: [
                                {
                                    text: 'Go to support tickets',
                                    handler: () => {
                                        this.toastController.dismiss();
                                        this.router.navigateByUrl('/support');
                                    }
                                },
                                {
                                    text: 'Dismiss',
                                    handler: () => this.toastController.dismiss()
                                }
                            ]
                        });
                        toast.present();
                    }
                });
        });
    }
}
