import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'abs-autocomplete',
    template: `
        <ion-list *ngLet="selectedIndex$ | async as selectedIndex">
            <ion-item
                *ngFor="let opt of options$ | async; let i = index"
                [color]="i === selectedIndex ? 'primary' : ''"
                (click)="dismiss(opt)"
                >{{ opt?.label || opt?.value || opt }}</ion-item
            >
        </ion-list>
    `,
    styles: [
        `
            ion-list {
                height: 300px;
                overflow-y: auto;
            }
        `
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AutoCompleteComponent {
    @Input()
    options$: BehaviorSubject<any[]>;

    @Input()
    selectedIndex$: BehaviorSubject<number>;

    constructor(private popoverController: PopoverController) {}

    dismiss(event) {
        this.popoverController.dismiss({ value: event.value || event, commit: true });
    }
}
