import { Component, OnInit } from '@angular/core';
import { BaseEntityFilterComponent } from './base-entity-filter.component';

@Component({
    selector: 'abs-entity-name-filter',
    template: `
        <form
            *ngLet="{
                query: query$ | async
            } as props"
        >
            <div class="form-group">
                <label>Name:</label>
                <ion-input [value]="props.query?.['name.en']" (ionInput)="updateQuery($event, 'name.en')"></ion-input>
            </div>
        </form>
    `
})
export class EntityNameFilterComponent extends BaseEntityFilterComponent implements OnInit {
    ngOnInit() {
        console.log('test');
        this.updateSort({ detail: { value: 'name' } });
    }
}
