import { FORCE_ACTIONS } from '../actions';
import { ForceState } from '../state';

export const deletePlatoonReducer = (state: ForceState, { forceId, platoonId }: ReturnType<typeof FORCE_ACTIONS.DELETE_PLATOON>) => {
    const updatedState = structuredClone(state);
    const force = updatedState.forces.find((f) => f.id === forceId);
    force.platoons = force.platoons.filter((p) => p.id !== platoonId);

    const platoonIdMap = force.platoons.reduce((agg, p, i) => {
        agg[p.id] = i;
        return agg;
    }, {});
    force.units = force.units
        .filter((u) => u.platoonId !== platoonId)
        .map((u) => {
            u.platoonId = platoonIdMap[u.platoonId];
            return u;
        });
    force.platoons = force.platoons.map((p, i) => ({ ...p, id: i }));
    return updatedState;
};
