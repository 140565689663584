import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { ModalModule } from '../global/modal';
import { ListModule } from '../global/components';
import { PageLayoutModule } from '../global/page-layout';
import { IssueDetailComponent } from './issue-detail.component';
import { SupportIssuesPage } from './support-issues.component';
import { ModalLayoutModule } from '../global/modal-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IssueRowComponent } from './support-issue-row.component';
import { StoreModule } from '@ngrx/store';
import { supportReducer } from './support.reducer';
import { SupportService } from './support.service';
import { IssueStatusComponent } from './issue-status.component';
import { PipesAndDirectivesModule } from '../global/pipes';
import { RelativeDateModule } from '../global/relative-date';
import { NgLetModule } from 'ng-let';
import { SupportComponent } from './support.component';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';

@NgModule({
    imports: [
        CommonModule,
        IonicModule,
        PageLayoutModule,
        ListModule,
        ModalModule,
        ModalLayoutModule,
        ReactiveFormsModule,
        RouterModule,
        StoreModule.forFeature('Support', supportReducer),
        PipesAndDirectivesModule,
        RelativeDateModule,
        NgLetModule,
        TranslateModule,
        FormsModule
    ],
    exports: [],
    declarations: [SupportIssuesPage, SupportComponent, IssueDetailComponent, IssueRowComponent, IssueStatusComponent],
    providers: [SupportService]
})
export class SupportModule {}
