import { Injectable } from '@angular/core';

import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import { Auth, OAuthProvider, getRedirectResult, signInWithPopup, signInWithRedirect } from '@angular/fire/auth';

import { UserService } from 'army-builder-shared';

export type DevicePlatform = 'android' | 'ios' | 'web';

@Injectable()
export class SocialSigninService {
    private provider = new OAuthProvider('google.com');

    constructor(private auth: Auth, public userService: UserService) {
        this.provider.setCustomParameters({
            prompt: 'select_account'
        });
    }

    handleCredentialResponse(token) {
        this.userService.login({ token });
        console.log('Token sent to server');
    }

    async googleLogin(platform: DevicePlatform) {
        try {
            let token: string;
            switch (platform) {
                case 'web':
                    token = await signInWithPopup(this.auth, this.provider).then((cred) => cred.user.getIdToken());
                    break;
                case 'android':
                    await FirebaseAuthentication.signOut();
                    // If the next line fails with "error 10", check that you've added
                    // the debug key to the Android app in the Firebase admin.  To generate
                    // the SHA1 key, run the following command:
                    // keytool -exportcert -list -v -alias androiddebugkey -keystore ~/.android/debug.keystore
                    await FirebaseAuthentication.signInWithGoogle();
                    token = (await FirebaseAuthentication.getIdToken()).token;
                    break;
                case 'ios':
                    await FirebaseAuthentication.signOut();
                    await FirebaseAuthentication.signInWithGoogle();
                    token = (await FirebaseAuthentication.getIdToken()).token;
                    break;
            }

            if (token) {
                this.handleCredentialResponse(token);
            }
        } catch (error) {
            console.error('Error logging in with Google', error);
        }
    }

    async appleLogin(_platform: DevicePlatform) {
        try {
            await FirebaseAuthentication.signOut();
            let res = await FirebaseAuthentication.signInWithApple();
            console.log({ res });
            let token = (await FirebaseAuthentication.getIdToken()).token;

            console.log({ token });
            if (token) {
                this.handleCredentialResponse(token);
            }
        } catch (error) {
            console.error('Error logging in with Apple', error);
        }
    }

    async facebookLogin(_platform: DevicePlatform) {
        try {
            await FirebaseAuthentication.signOut();
            let res = await FirebaseAuthentication.signInWithFacebook();
            console.log({ res });
            let token = (await FirebaseAuthentication.getIdToken()).token;

            console.log({ token });
            if (token) {
                this.handleCredentialResponse(token);
            }
        } catch (error) {
            console.error('Error logging in with Facebook', error);
        }
    }
}
