import { TranslateLoader } from '@ngx-translate/core';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, defaultIfEmpty, map, shareReplay, tap } from 'rxjs/operators';
import { AppInjector, RestDataService } from 'army-builder-shared';

import { environment } from '../../../environments/environment';
import { GAME_IDS } from '../../bolt-action/game-id.token';

export class WarlordCustomLoader implements TranslateLoader {
    private restDataService: RestDataService;
    protected translations: any = {};
    constructor(public gameId = 'global') {
        this.restDataService = AppInjector.get(RestDataService);
    }

    public getTranslation(lang: string): Observable<any> {
        const get = (restDataService: RestDataService, gameId: string, lang: string) => {
            // if (gameId === GAME_IDS.BOLT_ACTION_3E) {
            //     gameId = GAME_IDS.BOLT_ACTION_2E;
            // }
            const url = `${environment.apiBaseUrl}/translation/${gameId}/${lang}`;
            return restDataService
                .get(url, {
                    cacheKey: `translation_${gameId}_${lang}`,
                    preferCache: false
                })
                .pipe(
                    defaultIfEmpty({}),
                    map((x: any) => x.value),
                    catchError((err) => {
                        console.error(`Error loading translations from ${url}:`, this.gameId, err);
                        return of({});
                    }),
                    shareReplay(1)
                );
        };

        if (!this.translations[lang]) {
            this.translations[lang] = combineLatest([
                get(this.restDataService, this.gameId, lang),
                get(this.restDataService, 'global', lang)
            ]).pipe(
                map(([gameTranslations, globalTranslations]: any) => {
                    const res = {
                        ...gameTranslations,
                        GLOBAL: {
                            ...globalTranslations,
                            ...gameTranslations.GLOBAL
                        }
                    };
                    return res;
                }),
                catchError(() => of({}))
            );
        }

        return this.translations[lang];
    }
}
