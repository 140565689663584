import { Pipe, PipeTransform } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { HttpClientWithInFlightCache } from '../../../global';
import { FormSelectFieldWithSrc } from '../form-field';

@Pipe({
    name: 'absSelectOptions'
})
export class SelectOptionsPipe implements PipeTransform {
    constructor(private httpClient: HttpClientWithInFlightCache) {}

    async transform(field: any, formValue: any, parentFormGroup: UntypedFormGroup): Promise<any> {
        let items = await this.getSourceData(field, formValue, parentFormGroup);

        if (field.mapItems) {
            items = field.mapItems(items, field);
        }

        return items;
    }

    getSourceData(field: any, formValue: any, parentFormGroup: UntypedFormGroup) {
        const { srcField, filterBy, filterFn } = field;

        let res: any[];

        if (srcField) {
            res = this.getOptionsBySrc(field, formValue);
            if (filterFn) {
                res = res.filter((opt) => filterFn(opt, formValue, parentFormGroup));
            }
        } else if (filterBy) {
            const selectedValue = getFieldValueByPath(formValue, field.filterBy);
            const parentOption = field.options.find((opt: any) => opt.value === selectedValue) as any;
            res = parentOption?.items || [];
        } else if (filterFn) {
            res = field.options.filter((opt) => filterFn(opt, formValue, parentFormGroup));
        } else {
            res = field.options;
        }

        if (field.includeNone && !res.find((x) => x.value === null)) {
            res.unshift({ value: null, label: 'None' });
        }

        return res;
    }

    getOptionsBySrc(field: FormSelectFieldWithSrc, formValue: any) {
        const { srcField: src, srcFieldLabel, srcFieldValue } = field;
        const srcField = getFieldValueByPath(formValue, src);

        if (!isNaN(srcField.length)) {
            return srcField.map((item) => {
                const value = item[srcFieldValue];
                let label;

                if (field.mapItemName) {
                    label = field.mapItemName(item, formValue);
                } else {
                    label = item[srcFieldLabel];
                    if (label?.en) {
                        label = label.en;
                    }
                }
                return {
                    ...item,
                    value,
                    label
                };
            });
        } else {
            console.error(`Invalid target field for select: ${src}`, field);
            return [];
        }
    }
}

const getFieldValueByPath = (formValue: any, path: string) => {
    const parts = path.split('/');
    let field = formValue;
    while (parts.length > 0) {
        if (Array.isArray(field)) {
            const remainingParts = parts.join('/');
            const items = [];
            for (let i = 0; i < field.length; i++) {
                items.push(
                    ...getFieldValueByPath(field[i], remainingParts).map((x) => {
                        let val;
                        if (typeof x === 'object') {
                            val = { ...x };
                        } else {
                            val = { label: x, value: x };
                        }
                        return { ...val, parentId: field[i].id };
                    })
                );
            }
            field = items.flat(1);
            break;
        } else {
            const part = parts.shift();
            field = field[part];
        }
    }

    return field || [];
};
