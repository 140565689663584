import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { AppInjector, ArmyBuilderConfig } from '../../../global';

export const createTranslatableTagsFormGroup = () => {
    const config = AppInjector.get(ArmyBuilderConfig);
    const formGroup = new UntypedFormGroup({
        key: new UntypedFormControl()
    });
    for (const lang of config.supportedLanguages) {
        formGroup.addControl(lang, new UntypedFormControl());
    }
    return formGroup;
};
