import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { PageLayoutModule } from '../../global';

import { AnalyticsPage } from './analytics.page';

@NgModule({
    imports: [CommonModule, IonicModule, PageLayoutModule, FormsModule, ReactiveFormsModule],
    exports: [],
    declarations: [AnalyticsPage],
    providers: []
})
export class AnalyticsModule {}
