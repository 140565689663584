import { Params, RouterStateSnapshot } from '@angular/router';
import { RouterStateSerializer } from '@ngrx/router-store';
import { Injectable } from '@angular/core';

export interface RouterStateUrl {
    url: string;
    params: Params;
    queryParams: Params;
    trackingId: string;
    data: any;
}

@Injectable()
export class CustomSerializer implements RouterStateSerializer<RouterStateUrl> {
    serialize(routerState: RouterStateSnapshot): RouterStateUrl {
        let route = routerState.root;
        while (route.firstChild) {
            route = route.firstChild;
        }

        let trackingId = route.data ? route.data.trackingId : undefined;

        const {
            url,
            root: { queryParams }
        } = routerState;
        const { params } = route;

        // Only return the data we care about
        // instead of the entire snapshot
        return { url, params, queryParams, trackingId, data: route.data };
    }
}
