import { ToastController } from '@ionic/angular';
import { Color } from '@ionic/core';
import { Injectable } from '@angular/core';

import { parseIcons } from '../parseIcons';

export interface ToastButton {
    text: string;
    role: 'cancel' | 'ok';
    cssClass?: string;
}

export interface ToastOptions {
    color?: Color;
    buttons?: ToastButton[];
    duration?: number;
}

@Injectable({
    providedIn: 'root'
})
export class ToastService {
    constructor(private toastController: ToastController) {}

    private async show(header = '', message: string, opts: ToastOptions) {
        const toast = await this.toastController.create({
            header,
            color: opts.color ?? 'light',
            message: parseIcons(message),
            duration: opts.duration || 3000,
            buttons: opts?.buttons || [
                {
                    text: 'Ok',
                    role: 'cancel',
                    cssClass: 'toastButton'
                }
            ]
        });
        await toast.present();
        return toast.onDidDismiss();
    }

    async showToast(header: string, message: string, opts: ToastOptions) {
        return this.show(header, message, opts);
    }
}
