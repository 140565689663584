import { Directive, EventEmitter, HostListener, Input, OnDestroy, OnChanges, Output, SimpleChanges } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { HttpClientWithInFlightCache } from '../../../global';
import { MapItemsFn, SortOptionsFn } from '../form-field';

import { AutoCompleteComponent } from './autocomplete.component';

@Directive({ selector: '[absAutoComplete]' })
export class AutoCompleteDirective implements OnDestroy {
    @HostListener('keydown', ['$event']) keyDown(e) {
        if (e.keyCode === 13) {
            // Enter
            this.hide();
        } else if (e.keyCode === 40) {
            // Arrow down
            this.adjustSelectedItem$.next(1);
            e.preventDefault();
        } else if (e.keyCode === 38) {
            // Arrow up
            this.adjustSelectedItem$.next(-1);
            e.preventDefault();
        }
    }

    @HostListener('input', ['$event']) input(e) {
        this.inputEvent$.next(e);
    }

    popover: HTMLIonPopoverElement;

    @Input()
    options: any[];

    @Input()
    mapOptionsFn: MapItemsFn;

    @Input()
    sortOptionsFn: SortOptionsFn;

    inputEvent$ = new BehaviorSubject(null);
    matchingOptions$: Observable<any[]>;
    matchingOptionsSub: Subscription;
    adjustSelectedItem$ = new BehaviorSubject(0);

    _selectedIndex = -1;
    selectedIndex$: Observable<number>;

    @Output()
    selectionUpdate = new EventEmitter<string>();

    @Output()
    commitSelection = new EventEmitter<string>();

    constructor(
        private popoverController: PopoverController,
        private http: HttpClientWithInFlightCache
    ) {
        this.matchingOptions$ = this.inputEvent$.pipe(
            map((e) => {
                if (!e) {
                    return [];
                }

                const value = e.target.value;
                const matchingOptions = this.getMatches(this.options || [], value);

                if (matchingOptions.length === 0) {
                    this.hide();
                } else {
                    this.show(e);
                }

                if (this._selectedIndex > matchingOptions.length - 1) {
                    this._selectedIndex = -1;
                }

                return matchingOptions;
            })
        );
        this.matchingOptionsSub = this.matchingOptions$.subscribe();

        this.selectedIndex$ = combineLatest([this.matchingOptions$, this.adjustSelectedItem$.pipe(filter((i) => i !== 0))]).pipe(
            map(([matchingOptions, adjustment]) => {
                if (adjustment === 0) {
                    return this._selectedIndex;
                }

                if (
                    (adjustment > 0 && adjustment + this._selectedIndex <= matchingOptions.length - 1) ||
                    (adjustment < 0 && adjustment + this._selectedIndex > -1)
                ) {
                    this._selectedIndex += adjustment;
                }
                this.adjustSelectedItem$.next(0);
                const opt = matchingOptions[this._selectedIndex];
                this.selectionUpdate.emit(opt?.value || opt || '');
                return this._selectedIndex;
            })
        );
    }

    private getMatches(options, value) {
        const isMatch = (val) => val?.toLowerCase && val?.toLowerCase().includes(value.toLowerCase());
        return options
            .filter((opt) => (opt && isMatch(opt.label)) || isMatch(opt.value) || isMatch(opt))
            .sort((a, b) => {
                if (this.sortOptionsFn) {
                    return this.sortOptionsFn(a, b);
                }
                return 0;
            });
    }

    async show(e: any) {
        if (!this.popover) {
            this.popover = await this.popoverController.create({
                component: AutoCompleteComponent,
                componentProps: {
                    options$: this.matchingOptions$,
                    selectedIndex$: this.selectedIndex$
                },
                event: e,
                translucent: false,
                animated: false,
                keyboardClose: false,
                showBackdrop: false
            });
            await this.popover.present();
            e.target.focus();

            const dismissEvent = await this.popover.onDidDismiss();
            if (dismissEvent.data?.commit) {
                this.commitSelection.emit(dismissEvent.data.value);
            }
            delete this.popover;
        }
    }

    hide() {
        this._selectedIndex = -1;
        if (this.popover) {
            this.popoverController.dismiss();
            delete this.popover;
        }
    }

    ngOnDestroy() {
        if (this.matchingOptionsSub) {
            this.matchingOptionsSub.unsubscribe();
        }
    }
}
