import { createFeatureSelector, createSelector } from '@ngrx/store';

import { LoginState } from '../models';

import { defaultLoginState, SettingsState } from './reducer';

export const SettingsSelector = createFeatureSelector('settings');
export const LoginSelector = createSelector(SettingsSelector, (state: SettingsState) => state.global.login ?? defaultLoginState);
export const LoginCheckedSelector = createSelector(LoginSelector, (state: LoginState) => state.checked);
export const LanguageSelector = createSelector(SettingsSelector, (state: SettingsState) => state.global.selectedLanguage);
