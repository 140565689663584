import { NgModule } from '@angular/core';

import { CustomBehaviourHostDirective } from './custom-behaviours.directive';
import { CommonModule } from '@angular/common';

@NgModule({
    imports: [CommonModule],
    exports: [CustomBehaviourHostDirective],
    declarations: [CustomBehaviourHostDirective],
    providers: []
})
export class CustomBehavioursModule {}
