import { Injectable } from '@angular/core';
import { Observable, timer } from 'rxjs';
import { debounce, defaultIfEmpty, distinctUntilChanged, map, switchMap } from 'rxjs/operators';

import { SyncStatus } from '../data';
import { SettingsState } from '../settings/service/reducer';
import { snapshot } from '../utils';
import { updateSettingsState, updateSettingsValue } from '../settings';

import { BaseSyncService } from './sync.base';

@Injectable({ providedIn: 'root' })
export class SettingsSyncService extends BaseSyncService {
    entityType = 'settings';

    syncStatus$: Observable<{ state: SettingsState; status: SyncStatus }>;

    init() {
        this.syncStatus$ = this.store
            .select((s) => s.entityChange?.settings)
            .pipe(
                distinctUntilChanged(),
                switchMap((status: SyncStatus) =>
                    this.store.pipe(
                        map((s) => ({
                            state: s.settings as SettingsState,
                            status
                        }))
                    )
                )
            );

        this.syncStatus$.pipe(debounce(() => timer(3000))).subscribe((settings) => {
            if (settings.status === 'Dirty') {
                console.log('Uploading new settings', settings);
                this.uploadSettings(settings.state);
            }
        });
    }

    sync() {
        this.downloadSettings();
    }

    uploadSettings(settings: any) {
        const settingsToUpload = structuredClone(settings);
        if (settingsToUpload.global?.login) {
            delete settingsToUpload.global.login;
        }

        const headers = this.config.globalRequestHeaders;
        const promise: Observable<number> = this.httpClient.post(this.getSyncUrl(), settingsToUpload, {
            headers,
            requiresLogin: true
        });

        promise.pipe(this.handleSyncError()).subscribe((newTimestamp: number) => {
            const key = 'updatedAt';
            this.store.dispatch(updateSettingsValue({ gameId: 'global', settingsKey: key, newValue: newTimestamp }));
            this.complete('settings');
        });
    }

    downloadSettings() {
        const headers = this.config.globalRequestHeaders;
        this.httpClient
            .get(this.getSyncUrl(), { headers, requiresLogin: true })
            .pipe(
                this.handleSyncError(),
                map((serverData) => serverData?.[0]?.data)
            )
            .subscribe((serverData: SettingsState) => {
                if (serverData) {
                    if (serverData.global?.login) {
                        delete serverData.global.login;
                    }
                    this.store.dispatch(updateSettingsState(serverData));
                }
                this.complete('settings');
            });
    }
}
