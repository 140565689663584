import { Component } from '@angular/core';
import { BattleService } from '../../service/battle.service';

@Component({
    selector: 'abs-unit-status',
    template: `
        <abs-page-layout *ngIf="unit$ | async as unit" [title]="unit.unit.unitTemplate.name | language | async">
            Unit Status
        </abs-page-layout>
    `
})
export class UnitStatusPage {
    unit$ = this.battleService.unit$;
    constructor(protected battleService: BattleService) {}
}
