import { ArmyBuilderConfig, DEVICE$, GLOBAL_API_REQUEST_HEADERS, SettingsConfig } from 'army-builder-shared';

import { environment } from '../environments/environment';

import { API_REQUEST_HEADERS } from './global/request-headers';

export const SUPPORTED_LANGUAGES = ['en'];

export class WarlordConfig implements ArmyBuilderConfig {
    apiBaseUrl: string = environment.apiBaseUrl;
    environment: string;
    globalRequestHeaders: any = {
        ...GLOBAL_API_REQUEST_HEADERS,
        ...API_REQUEST_HEADERS
    };

    roles = {
        admin: 'app_admin',
        user: 'app_user'
    };

    version: string = environment.appVersion;
    paths: { statusEffectIcons: string };
    partitionUnitsByFaction = false;
    supportedLanguages = SUPPORTED_LANGUAGES;
    settings = {
        'bolt-action': {
            show_force_cost_on_unit_page: { type: 'boolean', defaultValue: false },
            enable_skills: { type: 'boolean', defaultValue: false }
        },
        'bolt-action-3e': {
            show_force_cost_on_unit_page: { type: 'boolean', defaultValue: false }
        }
    } as SettingsConfig;

    purchaseKey = 'wEilLpUTQQwzvgtjQEoJeOiXUmCxKodJ';
    subscriptionEnabled = false;
    device$ = DEVICE$;

    enableStripePurchases = true;
    purchaseKeys = {
        apple: 'appl_COSWLzjdZDWQZPylBqLINbgqNIS',
        google: 'goog_oNsowtZEZdqoJOXUTzwFCeXZXMK',
        stripe: 'strp_dWBJhGsYtxBVfYCptGLLYMLKrLS'
    };
}
