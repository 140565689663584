import { Component, Inject, Input, Output, EventEmitter } from '@angular/core';
import { StatusEffect, StatusEffectMap, StatusEffectValue } from '../../models';

@Component({
    selector: 'abs-status-indicators',
    template: `<abs-status-indicator
        *ngFor="let effect of effects"
        [effect]="effect"
        [readOnly]="readOnly"
        [value]="values?.[effect.key]"
        (change)="onEffectValueChanged(effect.key, $event)"
    ></abs-status-indicator>`
})
export class StatusIndicatorsComponent {
    @Input() readOnly: boolean;

    @Input()
    effects: StatusEffect[];

    @Input() values: StatusEffectMap = {};

    @Output()
    change = new EventEmitter<{ [key: string]: StatusEffectValue }>();

    onEffectValueChanged(key: string, value: StatusEffectValue) {
        this.change.emit({
            [key]: value
        });
    }
}
