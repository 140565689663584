import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ArmyBuilderConfig } from 'army-builder-shared';

import { UserService } from '../userService';

@Component({
    selector: 'subscription-handler',
    template: `<ion-spinner></ion-spinner>`,
    styles: [
        `
            :host {
                background: white;
            }
            ion-spinner {
                position: fixed;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        `
    ]
})
export class PurchaseWebHandlerComponent implements OnInit {
    constructor(
        private router: Router,
        private userService: UserService,
        private config: ArmyBuilderConfig
    ) {}

    async ngOnInit() {
        const url = this.config.apiBaseUrl + '/auth/refresh';
        await this.userService.refreshAccessToken(url, null);
        setTimeout(() => {
            this.router.navigateByUrl('/');
        }, 1000);
    }
}
