import { Component, OnInit } from '@angular/core';

import { ContentPageService } from './page.service';

@Component({
    selector: 'wl-content-page',
    template: `
        <abs-page-layout *ngIf="page$ | async as page" [title]="page.name | language | async">
            <div class="content" [innerHTML]="page.content | language | async"></div>
        </abs-page-layout>
    `,
    styles: [``]
})
export class ContentPage {
    page$ = this.pageService.page$;
    constructor(public pageService: ContentPageService) {}
}
