import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'formLabel'
})
export class FormLabelPipe implements PipeTransform {
    transform(value: string, ...args: any[]): any {
        if (value && value.match(/([a-z]{1}[A-Z]*)/) && value.length > 1) {
            let valWithSpaces = value.replace(/([A-Z])/g, ' $1');
            return valWithSpaces[0]?.toUpperCase() + valWithSpaces.slice(1);
        }
        return value;
    }
}
