import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { NgLetModule } from 'ng-let';
import { PageLayoutModule } from '../../global';
import { TranslationFormComponent } from './translation-form.component';
import { TranslationListComponent } from './translation-list.component';
import { TranslationService } from './translation.service';
import { TranslationsRoutes } from './translations.routes';

@NgModule({
    imports: [
        CommonModule,
        IonicModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule.forChild(TranslationsRoutes),
        PageLayoutModule,
        NgLetModule
    ],
    exports: [],
    declarations: [TranslationListComponent, TranslationFormComponent],
    providers: [TranslationService]
})
export class TranslationsModule {}
