import { createAction, props } from '@ngrx/store';

import { Force, Platoon, PointsAdjustment } from '../models';

export const FORCE_ACTIONS = {
    LOAD_ALL_FORCES: createAction('[Force] loadAllForces', props<{ forces: Force[] }>()),
    ADD_FORCE: createAction('[Force] addForce', props<{ force: Force }>()),
    UPDATE_FORCE: createAction('[Force] updateForce', props<{ force: Partial<Force> & { id: string } }>()),
    UPSERT_FORCE: createAction('[Force] upsertForce', props<{ force: Force }>()),
    DELETE_FORCE: createAction('[Force] deleteForce', props<{ id: string; updatedAt: number }>()),
    ADD_POINTS_ADJUSTMENT: createAction(
        '[Force] addPointsAdjustment',
        props<{ forceId: string; unitId?: string; pointsAdjustment: PointsAdjustment }>()
    ),
    REMOVE_POINTS_ADJUSTMENT: createAction('[Force] removePointsAdjustment', props<{ forceId: string; unitId?: string; index: number }>()),
    DELETE_PLATOON: createAction('[Force] deletePlatoon', props<{ forceId: string; platoonId: number }>()),
    DUPLICATE_PLATOON: createAction('[Force] duplicatePlatoon', props<{ forceId: string; platoonId: number }>()),
    ADD_PLATOON: createAction('[Force] addPlatoon', props<{ forceId: string; platoon: Platoon }>())
};
