import { ModalController } from '@ionic/angular';
import { ModalOptions } from '@ionic/core';
import { Injectable, TemplateRef } from '@angular/core';

import { ModalComponent } from './modal.component';

@Injectable()
export class Modal {
    constructor(private modalController: ModalController) {}

    async show(config: ModalConfig) {
        const modal = await this.modalController.create({
            component: config.component || ModalComponent,
            backdropDismiss: true,
            showBackdrop: true,
            componentProps: config.props
        });

        return modal.present();
    }

    async dismiss() {
        return this.modalController.dismiss();
    }
}

export type ModalConfig = Partial<Pick<ModalOptions, 'component'>> & {
    beforeDismiss?: Function;
    afterDismiss?: Function;
    props?: {
        contentTemplate?: TemplateRef<any>;
        buttonsTemplate?: TemplateRef<any>;
        cssClass?: string;
        title?: string;
        data?: any;
        buttonsDirection?: 'vertical' | 'horizontal';
        [propName: string]: any;
    };
};
