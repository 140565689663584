import { Injector, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
    imports: [CommonModule]
})
export class AppInjectorModule {
    constructor(private injector: Injector) {
        AppInjector = this.injector;
    }
}

export let AppInjector: Injector;
