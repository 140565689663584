<div class="group" [formGroup]="$any(parentFormGroup.controls[field.name])">
    <h2 *ngIf="field.header">{{ field.header }}</h2>
    <p *ngIf="field.description" [innerHTML]="field.description"></p>
    <div [class]="field.cssClass">
        <abs-universal-field
            *ngFor="let groupField of field.fields"
            [field]="groupField"
            [formValue]="formValue"
        ></abs-universal-field>
    </div>
</div>
