import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { map, distinctUntilChanged, filter, shareReplay, switchMap, defaultIfEmpty, debounceTime, tap } from 'rxjs/operators';
import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Platform } from '@ionic/angular';

import { Force, Unit } from '../../forces';
import { LocalStorageDataService } from '../../global/data';
import { NativeStorageService } from '../../global/data/native-storage.service';
import { DataLibrary, SettingsService, selectRouter } from '../../global';
import { BattleUnit } from '../models';

import { selectBattle } from './selectors';
import { BattleState, CUSTOM_BATTLE_REDUCERS, GlobalBattleState } from './reducer';
import {
    nextRound,
    setBattleData,
    resetRound,
    addUnitToBattle,
    setUnitStatus,
    removeUnitFromBattle,
    resetBattle,
    saveBattleUnit
} from './actions';

let battleDataLoaded = false;

@Injectable({ providedIn: 'root' })
export class BattleDataService extends LocalStorageDataService<Unit> {
    name = 'Battle-Unit';
}

@Injectable()
export abstract class BattleService implements OnDestroy {
    abstract gameId: string;
    battleDataLoaded$ = new BehaviorSubject(false);
    ready$ = combineLatest([this.dataLibrary.ready$, this.battleDataLoaded$]).pipe(
        map(([ready, battleDataLoaded]) => ready && battleDataLoaded)
    );

    route$: Observable<any> = this.store.select(selectRouter).pipe(distinctUntilChanged());

    units$: Observable<BattleUnit[]> = this.ready$.pipe(
        filter((ready) => ready),
        switchMap(() => this.settingsService.loggedIn$),
        filter((l) => l),
        switchMap(() =>
            this.state$.pipe(
                map((s) => {
                    return s[this.gameId]?.units ?? [];
                })
            )
        ),
        defaultIfEmpty([]),
        distinctUntilChanged((prev, current) => {
            if (prev?.length + current?.length === 0) {
                return true;
            }
            return prev === current;
        }),
        map((units) => {
            const res = this.processUnits(units);
            return res;
        }),
        distinctUntilChanged((prev, current) => {
            if (prev?.length + current?.length === 0) {
                return true;
            }
            return prev === current;
        }),
        shareReplay(1)
    ) as Observable<BattleUnit[]>;

    unitId$: Observable<any> = this.route$.pipe(
        map((r) => r.state.params.unitId),
        distinctUntilChanged()
    );

    unit$: Observable<BattleUnit> = combineLatest([this.units$, this.unitId$]).pipe(
        map(([units, unitId]: [BattleUnit[], string]) => {
            return units?.find((u) => u.id === unitId);
        }),
        filter((u) => !!u),
        distinctUntilChanged(),
        shareReplay(1)
    );

    state$: Observable<BattleState> = this.store.select(selectBattle);
    round$: Observable<number> = this.state$.pipe(
        map((s) => s[this.gameId]?.round ?? 1),
        distinctUntilChanged()
    );

    stateSub = this.state$.subscribe((x) => {
        // this.state = x;
    });

    constructor(
        protected store: Store,
        protected storage: NativeStorageService,
        protected settingsService: SettingsService,
        protected dataLibrary: DataLibrary,
        platform: Platform
    ) {
        platform.ready().then(() => {
            if (!battleDataLoaded) {
                // Hacky way to ensure data only gets loaded once
                // since this module is being lazy loaded, and thus
                // isn't a singleton
                this.init();
                this.battleDataLoaded$.next(true);
                battleDataLoaded = true;
            }
        });
    }

    abstract initGame();
    init() {
        this.initGame();
        this.storage.getItem(`Battle`, {}).then((value) => {
            if (value.units) {
                // The cached state predates the separation of battle state into properties for each gameId
                console.warn('Old battle state found - resetting to match new format.');
                value = {};
            }

            this.setState(value);
        });

        this.state$.pipe(debounceTime(1000)).subscribe((state) => {
            this.storage.setItem('Battle', state);
        });
    }

    resetBattle() {
        this.store.dispatch(resetBattle({ gameId: this.gameId, newState: CUSTOM_BATTLE_REDUCERS[this.gameId].initialBattleState }));
    }

    nextRound() {
        this.store.dispatch(nextRound({ gameId: this.gameId }));
    }

    resetRound() {
        this.store.dispatch(resetRound({ gameId: this.gameId }));
    }

    setState(value: GlobalBattleState) {
        this.store.dispatch(setBattleData({ newState: value }));
    }

    ngOnDestroy() {
        console.log('Battle svc onDestroy');
        this.stateSub.unsubscribe();
    }

    addUnit(battleUnit: BattleUnit) {
        this.store.dispatch(addUnitToBattle({ gameId: this.gameId, unit: battleUnit }));
    }

    updateUnit(battleUnit: BattleUnit) {
        this.store.dispatch(saveBattleUnit({ gameId: this.gameId, unit: battleUnit }));
    }

    addForceToBattle(force: Force) {
        force.units.forEach((unit) => {
            const battleUnit = this.createBattleUnit(unit, force);
            this.addUnit(battleUnit);
        });
    }

    createBattleUnit(unit: Unit, force: Force): BattleUnit {
        const id = this.generateRandomId();
        return {
            id,
            unit,
            forceId: force.id,
            forceName: force.name,
            status: {},
            modifiers: []
        };
    }

    generateRandomId() {
        return Date.now().toString() + Math.ceil(Math.random() * 10000);
    }

    saveUnitStatus(battleUnitId: string, statusKey: string, statusValue: any) {
        this.store.dispatch(setUnitStatus({ gameId: this.gameId, battleUnitId, statusKey, statusValue }));
    }

    delete(battleUnitId: string) {
        this.store.dispatch(removeUnitFromBattle({ gameId: this.gameId, battleUnitId }));
    }

    processUnits(units: BattleUnit[]) {
        return units;
    }
}
