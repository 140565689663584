import { Component } from '@angular/core';
import { ArmyBuilderConfig } from '../../../global/config';
import { ForceService } from '../../../forces/force.service';
import { Force } from '../../../forces/models';
import { BattleService } from '../../service/battle.service';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs/operators';

@Component({
    selector: 'abs-add-force-to-battle',
    template: `
        <abs-page-layout [title]="'BATTLE.HEADERS.ADD_FORCE' | translate">
            <ng-template #itemTemplate let-item="item">
                <div class="itemName">
                    <span>{{ item.name }}</span>
                </div>
            </ng-template>
            <abs-list [items]="forces$ | async" [template]="itemTemplate" (itemSelect)="itemSelected($event.item)"></abs-list>
        </abs-page-layout>
    `
})
export class AddForcePage {
    public forces$ = this.forceService.forces$.pipe(map((forces) => forces.filter((f) => f.units?.length > 0)));
    constructor(
        public config: ArmyBuilderConfig,
        public forceService: ForceService,
        public battleService: BattleService,
        public router: Router,
        public route: ActivatedRoute
    ) {}

    itemSelected(force: Force) {
        this.battleService.addForceToBattle(force);
        this.router.navigate(['..'], { relativeTo: this.route });
    }
}
