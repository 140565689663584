import { Pipe, PipeTransform } from '@angular/core';
import { ArmyBuilderConfig } from '../../../global/config';
import { StatusEffect, StatusEffectValue } from '../../models';

@Pipe({
    name: 'getIcon'
})
export class GetIconPipe implements PipeTransform {
    constructor(private cfg: ArmyBuilderConfig) {}
    transform(value: StatusEffectValue, effect: StatusEffect): string {
        let path = this.cfg?.paths?.statusEffectIcons;

        if (!path) {
            console.error('Config not found: statusEffectIcons');
            return;
        }

        if (effect.allowedValues && value !== undefined) {
            path += value;
        } else {
            path += effect.key;
        }

        return path + '.png';
    }
}
