import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { TranslationService } from './translation.service';
import * as ace from 'ace-builds';
import { snapshot, ArmyBuilderConfig } from '../../global';

@Component({
    selector: 'abs-translation-form',
    template: `
        <abs-page-layout title="Translations">
            <div
                class="content"
                *ngLet="{
                    lang: language$ | async,
                    formGroup: formGroup,
                    value: value$ | async,
                    valid: valid$ | async
                } as props"
            >
                <ng-container *ngIf="props.valid; else invalidLang">
                    <form
                        *ngIf="props.formGroup"
                        (ngSubmit)="onSubmit(props.formGroup.value)"
                        [formGroup]="props.formGroup"
                    >
                        <div class="form-group">
                            <ion-label>Language:</ion-label>
                            {{ props.lang }}
                        </div>
                        <div class="form-group">
                            <ion-label>Value:</ion-label>
                            <div class="app-ace-editor" #editor></div>
                            <textarea #value [formControlName]="'value'"></textarea>
                        </div>
                        <ion-button size="default" type="submit">Save</ion-button>
                    </form>
                </ng-container>
                <ng-template #invalidLang>
                    <p>Invalid language in URL.</p>
                </ng-template>
            </div>
        </abs-page-layout>
    `,
    styles: [
        `
            .app-ace-editor {
                border: 2px solid #f8f9fa;
                box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
                min-height: 200px;
            }

            .app-ace-editor * {
                font-family: monospace !important;
                font-size: 16px !important;
                direction: ltr !important;
                text-align: left !important;
            }

            textarea {
                display: none;
            }
        `
    ]
})
export class TranslationFormComponent implements AfterViewInit {
    value$: Observable<any>;
    formGroup: UntypedFormGroup;
    language$ = this.route.params.pipe(map((p) => p.lang));
    valid$ = this.language$.pipe(map((lang) => this.config.supportedLanguages.includes(lang)));

    @ViewChild('editor') private editor: ElementRef<HTMLElement>;
    @ViewChild('value') private value: ElementRef<HTMLElement>;

    aceEditor: ace.Ace.Editor;

    constructor(
        fb: UntypedFormBuilder,
        private toastController: ToastController,
        private route: ActivatedRoute,
        private translationService: TranslationService,
        private config: ArmyBuilderConfig
    ) {
        let { lang, gameId } = this.route.snapshot.params;

        this.value$ = this.translationService
            .getTranslationMap(lang, gameId)
            .pipe(map((value: any) => JSON.stringify(value?.value, null, 2)));

        this.value$.subscribe((val) => {
            this.formGroup = fb.group({
                value: fb.control(val)
            });
        });
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            ace.config.set('fontSize', '14px');
            ace.config.set('basePath', 'https://unpkg.com/ace-builds@1.4.12/src-noconflict');

            this.aceEditor = ace.edit(this.editor.nativeElement);
            this.aceEditor.setTheme('ace/theme/twilight');
            this.aceEditor.setOptions({
                maxLines: Infinity
            });
            this.aceEditor.commands.addCommand({
                name: 'save',
                bindKey: { win: 'Ctrl-S', mac: 'Cmd-S' },
                exec: (editor) => {
                    this.save(editor.session.getValue());
                }
            });
            this.aceEditor.session.setMode('ace/mode/json');

            snapshot(this.value$, (value) => {
                this.aceEditor.session.setValue(value);
            });

            this.aceEditor.on('change', () => {
                this.formGroup.patchValue({ value: this.aceEditor.getValue() });
            });
        }, 500); // using a timeout is a bit hacky
    }

    async onSubmit(e) {
        this.save(e.value);
    }

    async save(rawValue: string) {
        try {
            let value: any;
            try {
                value = JSON.parse(rawValue);
            } catch (e) {
                return this.presentToast('Error parsing translations - check that your JSON is valid.', 'danger');
            }

            this.save(value);
            let { lang, gameId } = this.route.snapshot.params;
            await this.translationService.saveTranslationMap(gameId, lang, value).toPromise();
            this.presentToast('Save successful!', 'success');
        } catch (e) {
            this.presentToast('Error saving translations - ' + e, 'danger');
        }
    }

    async presentToast(message, color) {
        const toast = await this.toastController.create({
            message,
            duration: 2000,
            color
        });
        toast.present();
    }

    async onKeyDown(e) {
        console.log(e);
        e.preventDefault();
    }
}
