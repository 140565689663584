import { Injectable } from '@angular/core';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { FileOpener } from '@capacitor-community/file-opener';
import { NavController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { ArmyBuilderConfig, DebugService, Logger } from 'army-builder-shared';
import { HttpClient } from '@angular/common/http';
import FileSaver from 'file-saver';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

import { Force } from '../../forces/models';

import { TABLE_LAYOUTS } from './functions/tableLayouts';

@Injectable()
export abstract class BasePDFGenerator {
    pdfObj = null;
    debug: Logger;
    translations: any;

    constructor(
        public navCtrl: NavController,
        protected plt: Platform,
        // protected qrCodeService: QRCodeService,
        protected debugService: DebugService,
        protected translateService: TranslateService,
        public platform: Platform,
        protected armyBuilderConfig: ArmyBuilderConfig,
        protected httpClient: HttpClient
    ) {
        this.debug = this.debugService.getInstance('[PDF Service]');
    }

    async generatePDF(force: Force, _pdfConfig: any) {
        const docDefinition = await this.createDocDefinition(force);
        this.downloadPdf(docDefinition, force);
    }

    abstract createDocDefinition(force: Force): Promise<any>;

    downloadPdf(docDefinition: any, force: Force) {
        this.debug.log(`Downloading PDF`);

        const pdfObj = pdfMake.createPdf(docDefinition, TABLE_LAYOUTS);
        const fileName = force.name + '.pdf';
        pdfObj.getBlob((blob) => {
            this.savePdf(blob, fileName);
        });
    }

    getBase64DataFromBlob(blob): Promise<string> {
        const reader = new FileReader();
        reader.readAsDataURL(blob);

        return new Promise((resolve, reject) => {
            reader.onloadend = function () {
                const base64data = reader.result;
                resolve(base64data.toString());
            };
        });
    }

    async savePdf(blob: Blob, fileName: string) {
        if (this.platform.is('cordova')) {
            console.log('Cordova PDF export');

            Filesystem.writeFile({
                path: fileName,
                data: await this.getBase64DataFromBlob(blob),
                directory: Directory.Library
                // encoding: FilesystemEncoding.UTF8
            }).then(
                (writeFileResponse) => {
                    console.log('writeFile success => ', writeFileResponse);
                    FileOpener.open({
                        filePath: writeFileResponse.uri,
                        contentType: 'application/pdf',
                        openWithDefault: true
                    })
                        .then(() => console.log('File is opened'))
                        .catch((e) => console.log('Error opening file', e));
                },
                (error) => {
                    console.log('writeFile error => ', error);
                }
            );
        } else {
            // On a browser simply use download
            // pdfObj.download(fileName);
            this.handleBrowserDownload(blob, fileName);
        }
    }

    handleBrowserDownload(blob: Blob, filename: string) {
        FileSaver.saveAs(blob, filename);
    }
}
