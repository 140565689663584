import { Injectable } from '@angular/core';
import { UserService, ArmyBuilderConfig, RestDataService } from '../../global';

@Injectable({ providedIn: 'root' })
export class TranslationService {
    private translationAPIUrl = `${this.config.apiBaseUrl}/translation`;

    constructor(private restDataService: RestDataService, private userService: UserService, private config: ArmyBuilderConfig) {}

    saveTranslationMap(gameId: string, language: string, value: any) {
        console.log('Saving translation map...', value);
        const url = `${this.translationAPIUrl}/${gameId}/${language}`;
        return this.userService.adminOnlyRoute('put', url, value);
    }

    getTranslationMap(language: string, gameId: string) {
        console.log('Fetching translation map...', gameId, language);
        const url = `${this.translationAPIUrl}/${gameId}/${language}`;
        return this.restDataService.get(url, {
            cacheKey: `translation_${gameId}_${language}`,
            preferCache: false
        });
    }
}
