import { Component, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';

@Component({
    selector: 'wl-cancel-subscription',
    template: `
        <abs-modal-layout [title]="'GLOBAL.SUBSCRIPTION.CANCEL' | translate">
            <div *ngIf="platform.is('android')">
                <p>
                    {{ 'GLOBAL.SUBSCRIPTION.CANCEL_ANDROID' | translate }}
                </p>
                <p>
                    {{ 'GLOBAL.SUBSCRIPTION.CANCEL_IOS' | translate }}
                    <a target="_blank" (click)="openLink('https://play.google.com/store/account/subscriptions')">
                        https://play.google.com/store/account/subscriptions
                    </a>
                </p>
            </div>
            <div *ngIf="platform.is('ios')">
                <p>
                    <a target="_blank" (click)="openLink('https://support.apple.com/en-gb/HT202039')">
                        https://support.apple.com/en-gb/HT202039
                    </a>
                </p>
            </div>
        </abs-modal-layout>
    `,
    styles: [
        `
            :host {
                background: none;
            }
        `
    ]
})
export class CancelSubscriptionComponent {
    constructor(public platform: Platform, private iab: InAppBrowser) {}

    openLink(url: string) {
        this.iab.create(url, '_system', 'location=yes');
        return false;
    }
}
