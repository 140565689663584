import { FORCE_ACTIONS } from '../actions';
import { ForceState } from '../state';

export const updateForceReducer = (state: ForceState, { force }: ReturnType<typeof FORCE_ACTIONS.UPDATE_FORCE>) => {
    return {
        ...state,
        forces: state.forces.map((f) => {
            if (force.id !== f.id) {
                return f;
            }

            return { ...f, ...force };
        })
    };
};
