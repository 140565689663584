import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntypedFormArray } from '@angular/forms';
import { FormTranslatableTagsField } from '../form-field';
import { TagsComponent } from '../tags/tags.component';
import { createTranslatableTagsFormGroup } from './functions';
import { TranslationModalComponent } from './translation-modal.component';

@Component({
    selector: 'abs-translatable-tags',
    template: `
        <div class="form-group" *ngIf="parentFormGroup" [formGroup]="parentFormGroup">
            <label *ngIf="!field.hideLabel">{{ field.label || (field.name | formLabel) }} </label>
            <p *ngIf="field.description" [innerHTML]="field.description"></p>
            <div [formGroupName]="field.name" class="tags">
                <span
                    *ngFor="let fc of formArray.controls; let i = index"
                    [formGroup]="$any(fc)"
                    (click)="showTranslation(fc.value, i)"
                    (auxclick)="$event.which === 2 ? removeItem($event, i) : null"
                >
                    {{ fc.value.key }} <span class="removeTag" (click)="removeItem($event, i)">x</span>

                    <input type="hidden" [formControlName]="'key'" />
                    <input type="hidden" *ngFor="let lang of supportedLanguages" [formControlName]="lang" />
                </span>

                <input
                    type="text"
                    [value]="value"
                    (input)="updateValue($event)"
                    (keydown.Enter)="onEnter($event)"
                    absAutoComplete
                    [options]="field.options"
                    [mapOptionsFn]="field.mapItems"
                    [sortOptionsFn]="field.sortOptionsFn ? field.sortOptionsFn(formValue) : undefined"
                    (selectionUpdate)="selectionUpdate($event)"
                    (commitSelection)="commitSelection($event)"
                />
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    styles: [
        `
            :host {
                display: block;
                position: relative;
            }
            .tags > span {
                cursor: pointer;
            }

            .removeTag {
                margin-left: 5px;
                cursor: pointer;
                padding: 5px;
            }
        `
    ]
})
export class TranslatableTagsComponent extends TagsComponent {
    @Input()
    field: FormTranslatableTagsField;

    supportedLanguages = this.config.supportedLanguages;

    getNewValue(value: string) {
        let opt: any = this.field.options?.find((o: any) => o === value || o.label === value);
        let key = opt?.value || opt || value;
        return { key };
    }

    getFormGroup() {
        return createTranslatableTagsFormGroup();
    }

    async showTranslation(value, i) {
        let tagsFormGroup = (this.parentFormGroup.get(this.field.name) as UntypedFormArray).at(i);
        let modal = await this.modalController.create({
            component: TranslationModalComponent,
            componentProps: {
                value,
                parentFormGroup: tagsFormGroup
            }
        });

        modal.onDidDismiss().then((data) => {
            console.log('Modal dismissed', data);
            tagsFormGroup.patchValue(data);
        });

        modal.present();
    }
}
