import { CommonModule } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { NgLetModule } from 'ng-let';

import { FilterModule } from '../filter/filter.module';
import { FormControlsModule } from '../form-controls/form-controls.module';
import { CustomBehavioursModule, PageLayoutModule } from '../../global';

import { AdminEntityService } from './service/entity.service';
import { PagerComponent, PagerQueryParamsPipe } from './components/pager/pager.component';
import { EntityFiltersModule } from './components/entity-filters/entity-filters.module';
import { EntityDetailsComponent, EntityListComponent, EntityNameFilterComponent } from './components';

@NgModule({
    exports: [],
    declarations: [EntityListComponent, EntityDetailsComponent, PagerComponent, PagerQueryParamsPipe, EntityNameFilterComponent],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FormControlsModule,
        PageLayoutModule,
        IonicModule,
        FilterModule,
        NgLetModule,
        EntityFiltersModule,
        RouterModule,
        CustomBehavioursModule
    ],
    providers: [AdminEntityService, provideHttpClient(withInterceptorsFromDi())]
})
export class EntityModule {}
