import { Routes } from '@angular/router';

import { PaywallComponent } from './paywall.component';
import { ManageSubscriptionComponent } from './manage-subscription.component';
import { PurchaseWebHandlerComponent } from 'army-builder-shared/src/lib/global/purchase/purchase-web-handler.page';

export const SUBSCRIPTION_ROUTES: Routes = [
    {
        path: 'manage',
        component: ManageSubscriptionComponent,
    },
    {
        path: 'subscribe',
        component: PaywallComponent,
    },
    {
        path: 'handle-purchase',
        component: PurchaseWebHandlerComponent
    }
];
