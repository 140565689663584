import { TranslateLoader } from '@ngx-translate/core';
import { forkJoin, Observable } from 'rxjs';
import { HttpClientWithInFlightCache } from '../httpClient';

export class CustomLoader implements TranslateLoader {
    constructor(private http: HttpClientWithInFlightCache) {}
    public getTranslation(lang: String): Observable<any> {
        let get = (file: string) => this.http.get('assets/i18n/' + lang + '/' + file + '.json');
        return forkJoin({
            GLOBAL: get('global'),
            FORCES: get('forces')
        });
    }
}
