import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';

import { FilterComponent } from './filter.component';

@NgModule({
    imports: [CommonModule, IonicModule],
    exports: [FilterComponent],
    declarations: [FilterComponent],
    providers: []
})
export class FilterModule {}
