import { Action, createAction, createReducer, createSelector, on, props } from '@ngrx/store';

export interface DebugStateModel {
    log: string[];
}

const defaultDebugState = {
    log: []
};

export const AppendLogMessage = createAction('[Debug] AppendLogMessage', props<{ message: string }>());
export const selectLog = createSelector(
    (state: any) => state.debug,
    (s: DebugStateModel) => s.log
);

export function debugStateReducer(state: DebugStateModel | undefined, action: Action) {
    return createReducer<DebugStateModel>(
        defaultDebugState,
        on(AppendLogMessage, (state, { message }) => ({
            ...state,
            log: [...state.log, message]
        }))
    )(state, action);
}
