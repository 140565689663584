import { Component } from '@angular/core';
import { Clipboard } from '@awesome-cordova-plugins/clipboard/ngx';

import { DebugService } from './debug.service';

@Component({
    selector: 'abs-debug',
    template: `
        <abs-page-layout [title]="'GLOBAL.SETTINGS.DEBUG.TITLE' | translate">
            <p [innerHTML]="'GLOBAL.SETTINGS.DEBUG.DESCRIPTION' | translate"></p>
            <div class="buttons">
                <ion-button (click)="copyToClipboard(log.value)">{{ 'GLOBAL.SETTINGS.DEBUG.COPY_LOG' | translate }}</ion-button>
            </div>
            <div class="content">
                <textarea #log readonly="true">{{ (debugService.log$ | async)?.join('\\n') }}</textarea>
            </div>
        </abs-page-layout>
    `,
    styles: [
        `
            .content {
            }
            textarea {
                width: 100%;
                height: 400px;
            }
        `
    ]
})
export class DebugComponent {
    constructor(
        public debugService: DebugService,
        private clipboard: Clipboard
    ) {}

    copyToClipboard(log: string) {
        this.clipboard.copy(log);
    }
}
