import { ChangeDetectionStrategy, Component, Input, TemplateRef } from '@angular/core';

@Component({
    selector: 'abs-tooltip',
    template: `
        <ion-card-content *ngIf="description" [innerHTML]="description"> </ion-card-content>
        <ion-card-content *ngIf="templateRef"><ng-container [ngTemplateOutlet]="templateRef"></ng-container></ion-card-content>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TooltipComponent {
    @Input()
    description: string;

    @Input()
    templateRef: TemplateRef<any>;
}
