import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { StatusEffect, StatusEffectValue } from '../../models';

@Component({
    selector: 'abs-status-indicator',
    template: `<img *ngIf="effect" [src]="value | getIcon: effect" (click)="selectNext()" [class.active]="!!value" />`,
    styles: [
        `
            img {
                max-width: var(--icon-size, '80px');
                max-height: var(--icon-size, '80px');
                opacity: 0.5;
            }

            .active {
                opacity: 1;
            }
        `
    ]
})
export class StatusIndicatorComponent implements OnChanges {
    @Input()
    effect: StatusEffect;

    @Input()
    value: StatusEffectValue;

    @Input()
    readOnly: boolean = false;

    @Output()
    change = new EventEmitter<StatusEffectValue>();

    selectNext() {
        if (this.readOnly) {
            return;
        }
        const allowedVals = this.effect.allowedValues;
        if (!!allowedVals && allowedVals.length > 0) {
            const len = allowedVals.length;

            let i = allowedVals.indexOf(this.value);

            if (i === -1) {
                i = allowedVals.indexOf(this.effect.defaultValue);
            }

            let nextValue = allowedVals[(i + 1) % len];
            this.change.emit(nextValue);
        } else {
            if (this.value === undefined) {
                this.value = this.effect.defaultValue;
            }
            this.change.emit(!this.value);
        }
    }

    ngOnChanges() {
        if (this.value === undefined && this.effect) {
            this.value = this.effect.defaultValue;
        }
    }
}
