import { Component, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { snapshot } from '../../../../global';

@Component({
    selector: 'abs-base-entity-filter',
    template: ``
})
export class BaseEntityFilterComponent {
    @Output()
    entityFilterChanged = new EventEmitter<any>();

    @Output()
    entitySortChanged = new EventEmitter<any>();

    query$: Observable<any> = this.route.queryParams.pipe(
        map((val) => {
            const query = {};
            const parts = decodeURIComponent(val.query || '')?.split('|');
            parts
                ?.filter((x) => x)
                .forEach((part) => {
                    let [key, value] = part.split(':');
                    query[key] = value?.split(',');
                });
            return query;
        })
    );

    sortValue$ = this.route.queryParams.pipe(map((val) => decodeURIComponent(val.sort)));

    constructor(private route: ActivatedRoute) {}

    updateQuery(e, field) {
        snapshot(this.query$, (query) => {
            query[field] = e.detail.value;
            this.entityFilterChanged.emit(query);
        });
    }

    updateSort(e) {
        const sort = e.detail.value;
        this.entitySortChanged.emit(sort || '');
    }
}
