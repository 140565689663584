import { Observable } from 'rxjs';
import { FormConfig } from '../../config/base-form.config';
import { BaseEntityFilterComponent } from '../components/entity-filters/base-entity-filter.component';
import { BaseEntityRowComponent } from '../components/entity-list/base-entity-row.component';

export abstract class EntityFormConfig {
    entityTypeMetaData: { [entityType: string]: { title: string; entityName: string; apiUrl: string } };
    forms: { [gameId: string]: { [entityType: string]: Observable<FormConfig> } };
    entityFilters: {
        [gameId: string]: {
            [entityType: string]: typeof BaseEntityFilterComponent;
        };
    } = {};

    entityRowTemplates: {
        [gameId: string]: {
            [entityType: string]: typeof BaseEntityRowComponent;
        };
    } = {};
}
