import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { FormTranslationField } from '../form-field';
import { TextboxComponent } from '../textbox/textbox.component';

@Component({
    selector: 'abs-translation',
    template: `
        <div *ngIf="parentFormGroup" [formGroup]="parentFormGroup">
            <div class="form-group" *ngIf="!field.multi" [formGroupName]="field.name">
                <label *ngIf="!field.hideLabel"
                    >{{ field.label || (field.name | formLabel) }}
                    <ion-icon class="info" *ngIf="field.description" name="information-circle" (click)="showDescription($event)"></ion-icon
                ></label>
                <ion-input *ngIf="!field.textarea" [formControlName]="'en'"></ion-input>
                <ion-textarea *ngIf="field.textarea" auto-grow [formControlName]="'en'"></ion-textarea>
            </div>

            <div class="form-group" *ngIf="field.multi" [formArrayName]="field.name">
                <label *ngIf="!field.hideLabel"
                    >{{ field.label || (field.name | formLabel) }}
                    <ion-icon class="info" *ngIf="field.description" name="information-circle" (click)="showDescription($event)"></ion-icon
                ></label>
                <div *ngFor="let item of formArray.value; let i = index" class="horizontal" [formGroupName]="i">
                    <ion-input *ngIf="!field.textarea" [formControlName]="'en'"></ion-input>
                    <ion-textarea *ngIf="field.textarea" auto-grow [formControlName]="'en'"></ion-textarea>
                    <ion-icon size="large" name="trash" (click)="removeItem(i)"></ion-icon>
                </div>
                <ion-button (click)="addItem()">Add Item</ion-button>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TranslationComponent extends TextboxComponent {
    @Input()
    field: FormTranslationField;

    addItem() {
        this.formArray.push(
            new UntypedFormGroup({
                en: new UntypedFormControl()
            })
        );
    }
}
