import { NgModule } from '@angular/core';
import { CapitalisePipe } from './capitalise.pipe';
import { ScaleToFitDirective } from './scale-to-fit.directive';

export * from './capitalise.pipe';
export * from './scale-to-fit.directive';

@NgModule({
    imports: [],
    exports: [CapitalisePipe, ScaleToFitDirective],
    declarations: [CapitalisePipe, ScaleToFitDirective],
    providers: []
})
export class PipesAndDirectivesModule {}
