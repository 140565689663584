import { Force } from '../../models';
import { FORCE_ACTIONS } from '../actions';
import { ForceState } from '../state';

export const addPlatoonReducer = (state: ForceState, { forceId, platoon }: ReturnType<typeof FORCE_ACTIONS.ADD_PLATOON>) => {
    console.log({ addPlatoonReducer: forceId });
    const updatedState = structuredClone(state);
    const force = updatedState.forces.find((f) => f.id === forceId) as Force;
    if (!force) {
        console.log('addPlatoonReducer', state);
    }
    const newPlatoon = { ...platoon };
    const platoonIds = force.platoons.map((p) => p.id);
    newPlatoon.id = Math.max(...platoonIds, -1) + 1;

    force.platoons.push(newPlatoon);

    return updatedState;
};
